import React from 'react';
import Context from '../../../assets/js/Context';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Component from "@reactions/component";
import { Form } from 'react-bootstrap';
// import 'cropperjs/dist/cropper.min.css';
import { Dialog, Pane, Spinner, Position, SideSheet } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from "universal-cookie";
import EditIcon from '@material-ui/icons/Edit';
import Login from '../../common/login';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import host from '../../../assets/js/Host';
import axios from 'axios';
// window.scrollTo(0, 55555555)   

const cookies = new Cookies();
const columns = [
    { field: "delete", name: "حذف", options: { width: 50, filter: true, sort: false, } },
    { field: "edit", name: "تعديل", options: { width: 50, filter: true, sort: false, } },
    { field: "type", name: "النوغ", options: { filter: true, sort: true, } },
    { field: "price", name: "المبلغ", options: { filter: true, sort: true, } },
    { field: "name", name: "الاسم", options: { filter: true, sort: true, } },
    { field: "image", name: "صوره", options: { width: 150, filter: true, sort: false, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    filter: false,
    print: false,
    download: false,
    rowsPerPage: 100,
    textLabels: {
        body: { noMatch: " لم يتم العثور على سجلات مطابقة", toolTip: "فرز", },
        pagination: { next: "الصفحة التالية", previous: "الصفحة السابقة", rowsPerPage: "عدد الصفوف", displayRows: "من", },
        toolbar: { search: "بحث", downloadCsv: "تنزيل", print: "Print", viewColumns: " التحكم بالاعمدة", filterTable: "فلتر", },
        filter: { all: "الكل", title: "فلتر", reset: "إعادة تعيين", },
        viewColumns: { title: "عرض الأعمدة", titleAria: "إظهار / إخفاء أعمدة الجدول", },
    }
}
class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            spin: true,
            spinCate: true,
            name: '',
            cost: '',
            type: '',
            file: [],
        }

    }

    componentDidMount() {
        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.get(host + `dashbord/services/all?`, { headers: header })
            .then(res => {
                let arr = [];
                console.log(res.data.data.services);
                for (let i = 0; i < res.data.data.services.length; i++) {

                    let obj = {
                        image: <img src={host + 'files/' + res.data.data.services[i].photo} alt='img' style={{ width: 100, height: 80 }} />,
                        name: res.data.data.services[i].name,
                        price: res.data.data.services[i].cost,
                        type: res.data.data.services[i].in_house === 1 ? 'منزليه' : 'خارجيه',
                        edit: <Component initialState={{
                            isShown: false, spinbtn: false,
                            name: res.data.data.services[i].name, cost: res.data.data.services[i].cost,
                            type: res.data.data.services[i].in_house, file: []
                        }}>
                            {({ state, setState }) => (
                                <React.Fragment>
                                    <SideSheet
                                        isShown={state.isShown}
                                        position={Position.LEFT}
                                        onCloseComplete={() => setState({ isShown: false })}
                                    >
                                        <div margin={40}>
                                            <div id='titleAddUser' style={{ backgroundColor: '#3A3A3A' }}>تعديل خدمه</div>
                                            <div id='inputAdd2Continer' >
                                                <div id={'ContinerInPut'} >
                                                    <label>الاسم </label>
                                                    <input autoComplete='off' type='text' placeholder='الاسم' id='InputTExtDash111'
                                                        value={state.name} onChange={(e) => setState({ name: e.target.value })} />
                                                </div>
                                                <div id={'ContinerInPut'} >
                                                    <label>المبلغ </label>
                                                    <input autoComplete='off' type='text' placeholder='المبلغ' id='InputTExtDash111'
                                                        value={state.cost} onChange={(e) => setState({ cost: e.target.value })} />
                                                </div>
                                                <div id={'ContinerInPut'} >
                                                    <label> نوع الخدمه </label>
                                                    <Form.Group style={{ direction: 'rtl' }} >
                                                        <Form.Control as="select" id='InputTExtDash111'
                                                            onChange={(even) => {
                                                                if (even.target.value !== 'Select') {
                                                                    setState({ type: even.target.value })
                                                                }
                                                            }}>
                                                            <option value="Select">اختيار</option>
                                                            <option value={0} >خارجيه</option>
                                                            <option value={1} >منزليه</option>
                                                        </Form.Control>

                                                    </Form.Group>
                                                </div>
                                                <div id={'ContinerInPut'}>
                                                    <label>صورة</label>

                                                    <input type='file' style={{ paddingRight: 4, paddingTop: 4 }}
                                                        id="InputTExtDash111"
                                                        onChange={file => {
                                                            console.log(file.target.files);
                                                            setState({ file: file.target.files })
                                                        }}
                                                        placeholder="Select the file "
                                                    />
                                                </div>
                                            </div>
                                            <div id='btnAddContiner'>
                                                {!state.spinbtn ?
                                                    <div id='BtnAddUser' style={{ backgroundColor: '#3A3A3A' }} onClick={() => {

                                                        setState({ spinbtn: true })
                                                        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
                                                        let formData = new FormData();
                                                        formData.append("name", state.name);
                                                        formData.append("cost", state.cost);
                                                        formData.append("in_house", state.type);
                                                        formData.append("file", state.file[0]);
                                                        axios({ url: host + `dashbord/services/${res.data.data.services[i].id}`, method: "PUT", data: formData, headers: header })
                                                            .then(response => {
                                                                setState({ isShown: false, spinbtn: false })
                                                                toast('تم التعديل بنجاح', {
                                                                    position: "bottom-center",
                                                                    autoClose: 5000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: true
                                                                });
                                                                this.componentDidMount();
                                                            })
                                                            .catch(error => {
                                                                setState({ spinbtn: false })
                                                                console.log(error.response)
                                                            })
                                                    }}>تعديل</div>
                                                    :
                                                    <div id='BtnAddUser' style={{ backgroundColor: '#3A3A3A' }} ><Spinner /></div>}
                                            </div>

                                        </div>
                                    </SideSheet>
                                    <div id='iconTableContiner' onClick={() => setState({ isShown: true })}>
                                        <EditIcon style={{ color: '#0c4c91', cursor: 'pointer' }} />
                                    </div>
                                </React.Fragment>
                            )}
                        </Component>,
                        delete: <Component initialState={{ isShown: false, spin: true, office_ids: [] }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="حذف خدمة "
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="حذف"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            var header = { "Content-Type": "application/json", token: cookies.get("token") };

                                            axios.delete(host + `dashbord/services/${res.data.data.services[i].id}`, { headers: header })
                                                .then(response => {
                                                    toast('تم الحذف بنجاح', {
                                                        position: "bottom-center",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true
                                                    });
                                                    setState({ isShown: false })
                                                    this.componentDidMount()
                                                })
                                                .catch(error => {
                                                    console.log(error.response)
                                                });
                                        }}
                                    >
                                        هل انت متاكد من عمليه الحذف
                                    </Dialog>
                                    <div id='iconTableContiner' onClick={() => { setState({ isShown: true }) }}>
                                        <DeleteForeverIcon style={{ color: '#d87373de', cursor: 'pointer' }} />
                                    </div>
                                </Pane>
                            )}
                        </Component>,
                    };
                    arr.push(obj);
                }
                this.setState({
                    items: arr, spinCate: false
                });
            })
            .catch(error => { console.log(error) })
        //   window.addEventListener('scroll',5535.5556640625)
        //  window.pageYOffset(5535)

    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })

    render() {
        // window.scrollTo(5535.5556640625,0)
        return (
            <Context.Consumer>
                {ctx => {
                    if (cookies.get("token")) {
                        if (!this.state.spinCate) {
                            return (
                                <div style={{ direction: 'rtl' }}>
                                    <div>
                                        <div id='btnHeaderContiner'>

                                            <Component initialState={{ isShown: false, spinbtn: false }}>
                                                {({ state, setState }) => (
                                                    <React.Fragment>
                                                        <SideSheet
                                                            isShown={state.isShown}
                                                            position={Position.LEFT}
                                                            onCloseComplete={() => setState({ isShown: false })}
                                                        >
                                                            <div margin={40}>
                                                                <div id='titleAddUser' style={{ backgroundColor: '#3A3A3A' }}>اضافه خدمه</div>
                                                                <div id='inputAdd2Continer' >
                                                                    <div id={'ContinerInPut'} >
                                                                        <label>الاسم </label>
                                                                        <input autoComplete='off' type='text' placeholder='الاسم' id='InputTExtDash111'
                                                                            onChange={(e) => this.setState({ name: e.target.value })} />
                                                                    </div>
                                                                    <div id={'ContinerInPut'} >
                                                                        <label>المبلغ </label>
                                                                        <input autoComplete='off' type='text' placeholder='المبلغ' id='InputTExtDash111'
                                                                            onChange={(e) => this.setState({ cost: e.target.value })} />
                                                                    </div>
                                                                    <div id={'ContinerInPut'} >
                                                                        <label> نوع الخدمه </label>
                                                                        <Form.Group style={{ direction: 'rtl' }} >
                                                                            <Form.Control as="select" id='InputTExtDash111'
                                                                                onChange={(even) => {
                                                                                    if (even.target.value !== 'Select') {
                                                                                        this.setState({ type: even.target.value })
                                                                                    }
                                                                                }}>
                                                                                <option value="Select">اختيار</option>
                                                                                <option value={0} >خارجيه</option>
                                                                                <option value={1} >منزليه</option>
                                                                            </Form.Control>

                                                                        </Form.Group>
                                                                    </div>
                                                                    <div id={'ContinerInPut'}>
                                                                        <label>صورة</label>

                                                                        <input type='file' style={{ paddingRight: 4, paddingTop: 4 }}
                                                                            id="InputTExtDash111"
                                                                            onChange={file => {
                                                                                console.log(file.target.files);
                                                                                this.setState({ file: file.target.files })
                                                                            }}
                                                                            placeholder="Select the file "
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div id='btnAddContiner'>
                                                                    {!state.spinbtn ?
                                                                        <div id='BtnAddUser' style={{ backgroundColor: '#3A3A3A' }} onClick={() => {

                                                                            setState({ spinbtn: true })
                                                                            var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
                                                                            let formData = new FormData();
                                                                            formData.append("name", this.state.name);
                                                                            formData.append("cost", this.state.cost);
                                                                            formData.append("in_house", this.state.type);
                                                                            formData.append("file", this.state.file[0]);
                                                                            axios({ url: host + `dashbord/services/add`, method: "POST", data: formData, headers: header })
                                                                                .then(response => {
                                                                                    setState({ isShown: false, spinbtn: false })
                                                                                    toast('تمت اضافة الخدمه بنجاح', {
                                                                                        position: "bottom-center",
                                                                                        autoClose: 5000,
                                                                                        hideProgressBar: false,
                                                                                        closeOnClick: true,
                                                                                        pauseOnHover: true,
                                                                                        draggable: true
                                                                                    });
                                                                                    this.componentDidMount();
                                                                                })
                                                                                .catch(error => {
                                                                                    setState({ spinbtn: false })
                                                                                    console.log(error.response)
                                                                                })
                                                                        }}>اضافة</div>
                                                                        :
                                                                        <div id='BtnAddUser' style={{ backgroundColor: '#3A3A3A' }} ><Spinner /></div>}
                                                                </div>

                                                            </div>
                                                        </SideSheet>
                                                        <div id='categoryContentContinerFirst' style={this.state.BtnAdd ? { display: 'none' } : { display: 'flex' }}>
                                                            <div id='btnADDCateGory' onClick={() => setState({ isShown: true })}>اضافة خدمه</div>
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </Component>

                                        </div>

                                        <div className='DataTableContiner'>
                                            <MuiThemeProvider
                                                theme={this.getMuiTheme()}>
                                                <MaterialDatatable data={this.state.items} columns={columns} options={options} />
                                            </MuiThemeProvider>
                                        </div>
                                    </div>
                                    <ToastContainer
                                        position="bottom-left"
                                        autoClose={10000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }}>
                                    <Spinner />
                                </div>
                            )
                        }
                    }
                    else {
                        return (
                            <Login />
                        )
                    }
                }
                }

            </Context.Consumer>
        )
    }
}

export default Category;