import React from 'react';
import Context from '../../../assets/js/Context';
import Component from "@reactions/component";
import { Table } from 'react-bootstrap';
// import 'cropperjs/dist/cropper.min.css';
import { Dialog, Pane, Spinner, SearchInput } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from "universal-cookie";
import ReactPaginate from 'react-paginate';
import Login from '../../common/login';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import qs from 'qs';
import { Tabs, Tab } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import CancelIcon from '@material-ui/icons/Cancel';
import host from '../../../assets/js/Host';
import axios from 'axios';
// window.scrollTo(0, 55555555)   

const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            page: '',
            Data1: [],
            page1: '',
            spinCate: true,
            activetap: 'wating'
        }

    }
    componentDidMount(activePage, search, limit) {
        this.setState({ spinPage: true })
        // var officeId = localStorage.getItem('officeId')
        var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };

        axios.get(host + `dashbord/post/all?status=0&search=${search ? search : ''}&limit=${limit ? limit : 20}&page=${activePage ? activePage : 1}`, { headers: header })
            .then(res => {
                console.log(res.data.data);
                this.setState({
                    Data: res.data.data.all_posts, spinCate: false,
                    page: res.data.data
                });
            })
            .catch(error => {
                console.log(error.response)
            })


        axios.get(host + `dashbord/post/all?status=1&search=${search ? search : ''}&limit=${limit ? limit : 20}&page=${activePage ? activePage : 1}`, { headers: header })
            .then(res => {
                console.log(res.data.data);
                this.setState({
                    Data1: res.data.data.all_posts, spinCate: false,
                    page1: res.data.data
                });
            })
            .catch(error => {
                console.log(error.response)
            })
    }

    render() {
        // window.scrollTo(5535.5556640625,0)
        return (
            <Context.Consumer>
                {ctx => {
                    if (cookies.get("token")) {
                        if (!this.state.spinCate) {
                            return (
                                <div style={{ direction: 'rtl' }}>
                                    <Paper className={'classesroot'} >
                                        <Tabs defaultActiveKey={this.state.activetap} id="uncontrolled-tab-example" style={{ backgroundColor: '#fff', }}>
                                            <Tab eventKey='wating' title="قيد الانتظار" tabClassName='wating' onClick={() => this.setState({ activetap: 'wating' })}>

                                                <div id='headSerachLimit'>
                                                    <SearchInput placeholder="بحث" style={{ paddingRight: 30 }} onChange={e => {
                                                        this.componentDidMount(1, e.target.value)
                                                    }} />
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <label style={{ marginLeft: 10, marginTop: 10 }}>عدد الصفوف</label>
                                                        <select className='InputTExtDash111' style={{ height: 30, width: 60, paddingRight: 5, paddingLeft: 5, textAlign: 'left' }}
                                                            name='city' onChange={(e) => {
                                                                if (e.target.value !== 'limit') {
                                                                    this.componentDidMount(1, '', e.target.value)
                                                                }
                                                            }}>
                                                            <option value={20}>20</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>
                                                        <div className='InputTExtDash111NN' style={{ height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            {this.state.page.totalItems}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='DataTableContiner' style={{ direction: 'rtl' }} >
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr style={{ textAlign: 'center' }}>
                                                                <th>صزره</th>
                                                                <th>الاسم</th>
                                                                <th>الهاتف</th>
                                                                <th>المحتوى</th>
                                                                <th>سماح</th>
                                                                <th>رفض</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.Data.map((data, i) =>
                                                                <tr key={i} style={{ textAlign: 'center' }}>
                                                                    <td><img src={host + 'files/' + data.photo} alt='img' style={{ width: 200, height: 200 }} /></td>

                                                                    <td>{data.user.name}</td>
                                                                    <td>{data.user.phone}</td>
                                                                    <td>{data.description}</td>

                                                                    <td><Component initialState={{ isShown: false }}>
                                                                        {({ state, setState }) => (
                                                                            <Pane>
                                                                                <Dialog
                                                                                    isShown={state.isShown}
                                                                                    title="السماح"
                                                                                    onCloseComplete={() => setState({ isShown: false })}
                                                                                    confirmLabel="موافقه"
                                                                                    cancelLabel="الغاء"
                                                                                    onConfirm={() => {
                                                                                        axios.post(host + `dashbord/post/allowed/${data.id}`,
                                                                                            qs.stringify({ allowed: 1 }), {
                                                                                            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") }
                                                                                        }).then(response => {
                                                                                            setState({ isShown: false })
                                                                                            toast('تم تغيير الحاله', {
                                                                                                position: "bottom-center",
                                                                                                autoClose: 5000,
                                                                                                hideProgressBar: false,
                                                                                                closeOnClick: true,
                                                                                                pauseOnHover: true,
                                                                                                draggable: true
                                                                                            });
                                                                                            this.componentDidMount()
                                                                                        }).catch(error => { console.log(error) })
                                                                                    }}
                                                                                >
                                                                                    هل انت متاكد من عملية السماح
                                                                                </Dialog>
                                                                                <CheckCircleIcon style={{ color: '#6ad287', cursor: 'pointer' }} onClick={() => setState({ isShown: true })} />
                                                                            </Pane>
                                                                        )}
                                                                    </Component>
                                                                    </td>
                                                                    <td><Component initialState={{ isShown: false }}>
                                                                        {({ state, setState }) => (
                                                                            <Pane>
                                                                                <Dialog
                                                                                    isShown={state.isShown}
                                                                                    title="رفض"
                                                                                    onCloseComplete={() => setState({ isShown: false })}
                                                                                    confirmLabel="تاكيد"
                                                                                    cancelLabel="الغاء"
                                                                                    onConfirm={() => {
                                                                                        var header = { "Content-Type": "application/json", token: cookies.get("token") };

                                                                                        axios.delete(host + `dashbord/post/delete/${data.id}`, { headers: header })
                                                                                            .then(response => {
                                                                                                toast('تم الحذف بنجاح', {
                                                                                                    position: "bottom-center",
                                                                                                    autoClose: 5000,
                                                                                                    hideProgressBar: false,
                                                                                                    closeOnClick: true,
                                                                                                    pauseOnHover: true,
                                                                                                    draggable: true
                                                                                                });
                                                                                                setState({ isShown: false })
                                                                                                this.componentDidMount()
                                                                                            })
                                                                                            .catch(error => {
                                                                                                console.log(error.response)
                                                                                            });
                                                                                    }}
                                                                                >
                                                                                    هل انت متاكد من عملية الرفض
                                                                                </Dialog>
                                                                                <CancelIcon style={{ color: '#d26a6f', cursor: 'pointer' }} onClick={() => setState({ isShown: true })} />
                                                                            </Pane>
                                                                        )}
                                                                    </Component>
                                                                    </td>

                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>

                                                </div>
                                                <div id='pagnationContiner'>
                                                    <ReactPaginate
                                                        previousLabel={'السابق'}
                                                        nextLabel={'التالي'}
                                                        breakLabel={'...'}
                                                        breakClassName={'break-me'}
                                                        pageCount={this.state.page.totalpages}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={8}
                                                        forcePage={this.state.activePage}
                                                        onPageChange={(page) => this.handlePageClick(page)}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                        activeClassName={'active'}
                                                    />
                                                </div>
                                            </Tab>
                                            <Tab eventKey="done" title="المسموح بها" tabClassName='done' onClick={() => this.setState({ activetap: 'done' })}>
                                                <div id='headSerachLimit'>
                                                    <SearchInput placeholder="بحث" style={{ paddingRight: 30 }} onChange={e => {
                                                        this.componentDidMount(1, e.target.value)
                                                    }} />
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <label style={{ marginLeft: 10, marginTop: 10 }}>عدد الصفوف</label>
                                                        <select className='InputTExtDash111' style={{ height: 30, width: 60, paddingRight: 5, paddingLeft: 5, textAlign: 'left' }}
                                                            name='city' onChange={(e) => {
                                                                if (e.target.value !== 'limit') {
                                                                    this.componentDidMount(1, '', e.target.value)
                                                                }
                                                            }}>
                                                            <option value={20}>20</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>
                                                        <div className='InputTExtDash111NN' style={{ height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            {this.state.page1.totalItems}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='DataTableContiner' style={{ direction: 'rtl' }} >
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr style={{ textAlign: 'center' }}>
                                                                <th>صزره</th>
                                                                <th>الاسم</th>
                                                                <th>الهاتف</th>
                                                                <th>المحتوى</th>
                                                                <th>رفض</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.Data1.map((data, i) =>
                                                                <tr key={i} style={{ textAlign: 'center' }}>
                                                                    <td><img src={host + 'files/' + data.photo} alt='img' style={{ width: 200, height: 200 }} /></td>

                                                                    <td>{data.user.name}</td>
                                                                    <td>{data.user.phone}</td>
                                                                    <td>{data.description}</td>
                                                                    <td><Component initialState={{ isShown: false }}>
                                                                        {({ state, setState }) => (
                                                                            <Pane>
                                                                                <Dialog
                                                                                    isShown={state.isShown}
                                                                                    title="رفض"
                                                                                    onCloseComplete={() => setState({ isShown: false })}
                                                                                    confirmLabel="تاكيد"
                                                                                    cancelLabel="الغاء"
                                                                                    onConfirm={() => {
                                                                                        var header = { "Content-Type": "application/json", token: cookies.get("token") };

                                                                                        axios.delete(host + `dashbord/post/delete/${data.id}`, { headers: header })
                                                                                            .then(response => {
                                                                                                toast('تم الحذف بنجاح', {
                                                                                                    position: "bottom-center",
                                                                                                    autoClose: 5000,
                                                                                                    hideProgressBar: false,
                                                                                                    closeOnClick: true,
                                                                                                    pauseOnHover: true,
                                                                                                    draggable: true
                                                                                                });
                                                                                                setState({ isShown: false })
                                                                                                this.componentDidMount()
                                                                                            })
                                                                                            .catch(error => {
                                                                                                console.log(error.response)
                                                                                            });
                                                                                    }}
                                                                                >
                                                                                    هل انت متاكد من عملية الرفض
                                                                                </Dialog>
                                                                                <CancelIcon style={{ color: '#d26a6f', cursor: 'pointer' }} onClick={() => setState({ isShown: true })} />
                                                                            </Pane>
                                                                        )}
                                                                    </Component>
                                                                    </td>

                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>

                                                </div>
                                                <div id='pagnationContiner'>
                                                    <ReactPaginate
                                                        previousLabel={'السابق'}
                                                        nextLabel={'التالي'}
                                                        breakLabel={'...'}
                                                        breakClassName={'break-me'}
                                                        pageCount={this.state.page1.totalpages}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={8}
                                                        forcePage={this.state.activePage}
                                                        onPageChange={(page) => this.handlePageClick(page)}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                        activeClassName={'active'}
                                                    />
                                                </div>

                                            </Tab>
                                        </Tabs>
                                    </Paper>

                                    <ToastContainer
                                        position="bottom-left"
                                        autoClose={10000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }}>
                                    <Spinner />
                                </div>
                            )
                        }
                    }
                    else {
                        return (
                            <Login />
                        )
                    }
                }
                }

            </Context.Consumer>
        )
    }
}

export default Category;