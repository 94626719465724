import React from 'react';
import Component from "@reactions/component";
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import GradientIcon from '@material-ui/icons/Gradient';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import PeopleIcon from '@material-ui/icons/People';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import CategoryIcon from '@material-ui/icons/Category';
import CardGiftcard from '@material-ui/icons/CardGiftcard';
import ExtensionIcon from '@material-ui/icons/Extension';
import SettingsIcon from '@material-ui/icons/Settings';
// import CodeIcon from '@material-ui/icons/Code';
import Setting from '../dash/office/setting';
import OrdersFast from '../dash/office/orderFast';
import InstagramIcon from '@material-ui/icons/Instagram';
import DnsIcon from '@material-ui/icons/Dns';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import Category from '../dash/category/category';
import Items from '../dash/office/cateByOffice';
import Banner from '../dash/banner/banner';
import Orders from '../dash/office/Orders';
import OrderServices from '../dash/office/OrderServices';
import Services from '../dash/office/Services';
import Media from '../dash/office/media';
import Governorate from '../dash/office/Governorate';
import Users from '../dash/office/Users';
import Gifts from '../dash/office/Gifts';
import Promocode from './Promocode';
import Login from './login';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { SideSheet, Position, } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import qs from 'qs';
import Logo from '../../assets/img/logo.png';
const cookies = new Cookies();
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        direction: 'rtl',
        display: 'flex',
        paddingTop: 0,
    },
    drawer: {
        paddingTop: 0,
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    BTNSlideContiner: {

        [theme.breakpoints.up('sm')]: {

            paddingTop: 20,
            height: '90vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        paddingTop: 50,
        height: '90vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    menuButton: {
        marginRight: theme.spacing(2),
        zIndex: 11,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },

    BTNSlide: {
        width: 200,
        // width:drawerWidth,
        display: 'flex',
        alignItems: 'center',
        padding: 10,
        marginBottom: 10,
        color: '#ffffff',
        borderRadius: 5,
        backgroundColor: '#111111',
        height: 40,
        cursor: 'pointer',

        '&:hover': {
            transform: ' scale(1.04)',
            backgroundColor: 'rgba(12,46,145,0.8)',
            color: '#fbc85c!important',
            transitionDuration: '.3s',
            transitionTimingFunction: 'linear',
        },
        '&:active': {
            transform: ' scale(1)',
            backgroundColor: 'rgba(12,46,145,0.8)',
            color: '#fbc85c!important',
            transitionDuration: '.3s',
            transitionTimingFunction: 'linear',
        }

    },
    BTNSlideName: {
        marginRight: 15,
        fontSize: 18
    },
    toolbar: {

        marginTop: -24,
    },
    drawerPaper: {
        backgroundColor: '#4eadc9',
        width: drawerWidth,
        direction: 'rtl',
    },
    content: {
        flexGrow: 1,
        // padding: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },

}));

function ResponsiveDrawer(props) {
    const { container } = props;
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div className={classes.BTNSlideContiner}>
            <div>

                <div className={classes.toolbar} />
                < NavLink activeClassName='ActiveSliderBTN' to='/Category' >
                    <div className={classes.BTNSlide}>
                        <CategoryIcon id='iconActive' />
                        <article className={classes.BTNSlideName}>الاقسام</article>
                    </div>
                </NavLink>

                <NavLink activeClassName='ActiveSliderBTN' to='/Items' >
                    <div className={classes.BTNSlide}>
                        <ExtensionIcon id='iconActive' />
                        <article className={classes.BTNSlideName}>المواد</article>
                    </div>
                </NavLink>
                <NavLink activeClassName='ActiveSliderBTN' to='Gifts' >
                    <div className={classes.BTNSlide}>
                        <CardGiftcard id='iconActive' />
                        <article className={classes.BTNSlideName}> الهدايا </article>
                    </div>
                </NavLink>
                <NavLink activeClassName='ActiveSliderBTN' to='Services' >
                    <div className={classes.BTNSlide}>
                        <DnsIcon id='iconActive' />
                        <article className={classes.BTNSlideName}> الخدمات </article>
                    </div>
                </NavLink>
                <NavLink activeClassName='ActiveSliderBTN' to='Media' >
                    <div className={classes.BTNSlide}>
                        <InstagramIcon id='iconActive' />
                        <article className={classes.BTNSlideName}> الميديا </article>
                    </div>
                </NavLink>
                <NavLink activeClassName='ActiveSliderBTN' to='/Banner' >
                    <div className={classes.BTNSlide}>
                        <GradientIcon id='iconActive' />
                        <article className={classes.BTNSlideName}> الاعلانات </article>
                    </div>
                </NavLink>
                {/* <NavLink activeClassName='ActiveSliderBTN' to='/Promocode' >
                    <div className={classes.BTNSlide}>
                        <CodeIcon id='iconActive' />
                        <article className={classes.BTNSlideName}>بروموكود</article>
                    </div>
                </NavLink> */}
                <NavLink activeClassName='ActiveSliderBTN' to='/Orders' >
                    <div className={classes.BTNSlide}>
                        <MenuBookIcon id='iconActive' />
                        <article className={classes.BTNSlideName}>الطلبات</article>
                    </div>
                </NavLink>
                <NavLink activeClassName='ActiveSliderBTN' to='/OrdersFast' >
                    <div className={classes.BTNSlide}>
                        <MenuBookIcon id='iconActive' />
                        <article className={classes.BTNSlideName}>طلبات سريعه</article>
                    </div>
                </NavLink>
                <NavLink activeClassName='ActiveSliderBTN' to='/OrderServices' >
                    <div className={classes.BTNSlide}>
                        <MenuBookIcon id='iconActive' />
                        <article className={classes.BTNSlideName}>طلبات الخدمات</article>
                    </div>
                </NavLink>

                <Component initialState={{ isShown: false, title: '', body: '' }}>
                    {({ state, setState }) => (
                        <React.Fragment>
                            <SideSheet
                                isShown={state.isShown}
                                position={Position.LEFT}
                                onCloseComplete={() => setState({ isShown: false })}
                            >
                                <div margin={40}>
                                    <div id='titleAddUser' style={{ backgroundColor: '#3A3A3A' }}>ارسال اشعارات</div>
                                    <div id='inputAdd2Continer' >

                                        <div id={'ContinerInPut'} >
                                            <label>العنوان </label>
                                            <input autoComplete='off' type='text' id='InputTExtDash111' style={{ height: 32, width: 250 }}
                                                onChange={(e) => setState({ title: e.target.value })} />
                                        </div>
                                        <div id={'ContinerInPut'} >
                                            <label> المحتوى </label>
                                            <textarea type='text' id='inputTextAreaSideNew' style={{ width: 250, minHeight: 100 }}
                                                onChange={(e) => setState({ body: e.target.value })} />

                                        </div>

                                    </div>
                                    <div id='btnAddContiner'>
                                        <div id='BtnAddUser' style={{ backgroundColor: '#3A3A3A' }} onClick={() => {
                                            axios.post(host + `dashbord/notification`,
                                                qs.stringify({
                                                    title: state.title,
                                                    body: state.body,
                                                }), {
                                                headers: { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") }
                                            }).then(response => {
                                                setState({ isShown: false })
                                                toast("تم الارسال بنجاح", {
                                                    position: "bottom-center",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true
                                                });
                                            }).catch(error => {
                                                console.log(error.response.data)
                                            })

                                        }}>ارسال</div>
                                    </div>

                                </div>
                            </SideSheet>
                            <div className={classes.BTNSlide} onClick={() => setState({ isShown: true })}>
                                <NotificationsActiveIcon id='iconActive' />
                                <article className={classes.BTNSlideName}>ارسال اشعارات</article>
                            </div>
                        </React.Fragment>
                    )}
                </Component>
                <NavLink activeClassName='ActiveSliderBTN' to='/Users' >
                    <div className={classes.BTNSlide}>
                        <PeopleIcon id='iconActive' />
                        <article className={classes.BTNSlideName}>المستخدمين</article>
                    </div>
                </NavLink>
                <NavLink activeClassName='ActiveSliderBTN' to='/Governorate' >
                    <div className={classes.BTNSlide}>
                        <LocationCityIcon id='iconActive' />
                        <article className={classes.BTNSlideName}>المحافظات والمدن</article>
                    </div>
                </NavLink>
                <NavLink activeClassName='ActiveSliderBTN' to='/Setting' >
                    <div className={classes.BTNSlide}>
                        <SettingsIcon id='iconActive' />
                        <article className={classes.BTNSlideName}>الاعدادات</article>
                    </div>
                </NavLink>
                <NavLink activeClassName='ActiveSliderBT' to='/' onClick={() => {
                    cookies.remove("token")
                    window.location.href = '/'
                }}>


                    <div className={classes.BTNSlide}>
                        <MeetingRoomIcon id='iconActive' />
                        <article className={classes.BTNSlideName}>تسجيل الخروج</article>
                    </div>
                </NavLink>

            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <img src={Logo} alt='logo' id='logoSideBar' />
            </div>
        </div>
    );
    if (cookies.get("token")) {
        return (
            <div className={classes.root}>

                <Toolbar id='NavContinerApp'>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
                <nav className={classes.drawer} aria-label="mailbox folders">

                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden smUp implementation="css">

                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor='right'
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            <div >

                                {drawer}
                            </div>
                        </Drawer>
                    </Hidden>

                    <Hidden xsDown implementation="css">

                        <Drawer
                            anchor='right'
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            <div style={{ marginTop: 29 }}>

                                {drawer}
                            </div>
                        </Drawer>
                    </Hidden>
                </nav>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    {renderPage(props)}
                </main>
                <ToastContainer
                    position="bottom-left"
                    autoClose={10000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
            </div>
        )
    }
    else {
        return (
            <Login />
        )
    }

}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};
const renderPage = (props) => {
    if (props.match.path === '/Category') {
        return (<Category />)
    }
    else if (props.match.path === '/Items') {
        return (<Items />)
    }
    else if (props.match.path === '/Media') {
        return (<Media />)
    }
    else if (props.match.path === '/Banner') {
        return (<Banner />)
    }
    else if (props.match.path === '/Services') {
        return (<Services />)
    }
    else if (props.match.path === '/OrderServices') {
        return (<OrderServices />)
    }
    else if (props.match.path === '/Orders') {
        return (<Orders />)
    }
    else if (props.match.path === '/Governorate') {
        return (<Governorate />)
    }
    else if (props.match.path === '/Users') {
        return (<Users />)
    }
    else if (props.match.path === '/Promocode') {
        return (<Promocode />)
    }
    else if (props.match.path === '/OrdersFast') {
        return (<OrdersFast />)
    }
    else if (props.match.path === '/Gifts') {
        return (<Gifts />)
    }
    else if (props.match.path === '/Setting') {
        return (<Setting />)
    }
}
export default ResponsiveDrawer;
