import React from 'react';
import Context from '../../../assets/js/Context';
import Component from "@reactions/component";
import { Tabs, Tab } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { Spinner, SearchInput, Pane, Dialog } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InfoIcon from '@material-ui/icons/Info';
// import Component from "@reactions/component";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Cookies from "universal-cookie";
import host from '../../../assets/js/Host';
import axios from 'axios';
import { TelegramShareButton, TelegramIcon, WhatsappShareButton, WhatsappIcon } from "react-share";
import { Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import MaterialDatatable from "material-datatable";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import * as moment from "moment-timezone";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Map, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
var myIcon = new L.Icon({
    iconUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAFgUlEQVR4Aa1XA5BjWRTN2oW17d3YaZtr2962HUzbDNpjszW24mRt28p47v7zq/bXZtrp/lWnXr337j3nPCe85NcypgSFdugCpW5YoDAMRaIMqRi6aKq5E3YqDQO3qAwjVWrD8Ncq/RBpykd8oZUb/kaJutow8r1aP9II0WmLKLIsJyv1w/kqw9Ch2MYdB++12Onxee/QMwvf4/Dk/Lfp/i4nxTXtOoQ4pW5Aj7wpici1A9erdAN2OH64x8OSP9j3Ft3b7aWkTg/Fm91siTra0f9on5sQr9INejH6CUUUpavjFNq1B+Oadhxmnfa8RfEmN8VNAsQhPqF55xHkMzz3jSmChWU6f7/XZKNH+9+hBLOHYozuKQPxyMPUKkrX/K0uWnfFaJGS1QPRtZsOPtr3NsW0uyh6NNCOkU3Yz+bXbT3I8G3xE5EXLXtCXbbqwCO9zPQYPRTZ5vIDXD7U+w7rFDEoUUf7ibHIR4y6bLVPXrz8JVZEql13trxwue/uDivd3fkWRbS6/IA2bID4uk0UpF1N8qLlbBlXs4Ee7HLTfV1j54APvODnSfOWBqtKVvjgLKzF5YdEk5ewRkGlK0i33Eofffc7HT56jD7/6U+qH3Cx7SBLNntH5YIPvODnyfIXZYRVDPqgHtLs5ABHD3YzLuespb7t79FY34DjMwrVrcTuwlT55YMPvOBnRrJ4VXTdNnYug5ucHLBjEpt30701A3Ts+HEa73u6dT3FNWwflY86eMHPk+Yu+i6pzUpRrW7SNDg5JHR4KapmM5Wv2E8Tfcb1HoqqHMHU+uWDD7zg54mz5/2BSnizi9T1Dg4QQXLToGNCkb6tb1NU+QAlGr1++eADrzhn/u8Q2YZhQVlZ5+CAOtqfbhmaUCS1ezNFVm2imDbPmPng5wmz+gwh+oHDce0eUtQ6OGDIyR0uUhUsoO3vfDmmgOezH0mZN59x7MBi++WDL1g/eEiU3avlidO671bkLfwbw5XV2P8Pzo0ydy4t2/0eu33xYSOMOD8hTf4CrBtGMSoXfPLchX+J0ruSePw3LZeK0juPJbYzrhkH0io7B3k164hiGvawhOKMLkrQLyVpZg8rHFW7E2uHOL888IBPlNZ1FPzstSJM694fWr6RwpvcJK60+0HCILTBzZLFNdtAzJaohze60T8qBzyh5ZuOg5e7uwQppofEmf2++DYvmySqGBuKaicF1blQjhuHdvCIMvp8whTTfZzI7RldpwtSzL+F1+wkdZ2TBOW2gIF88PBTzD/gpeREAMEbxnJcaJHNHrpzji0gQCS6hdkEeYt9DF/2qPcEC8RM28Hwmr3sdNyht00byAut2k3gufWNtgtOEOFGUwcXWNDbdNbpgBGxEvKkOQsxivJx33iow0Vw5S6SVTrpVq11ysA2Rp7gTfPfktc6zhtXBBC+adRLshf6sG2RfHPZ5EAc4sVZ83yCN00Fk/4kggu40ZTvIEm5g24qtU4KjBrx/BTTH8ifVASAG7gKrnWxJDcU7x8X6Ecczhm3o6YicvsLXWfh3Ch1W0k8x0nXF+0fFxgt4phz8QvypiwCCFKMqXCnqXExjq10beH+UUA7+nG6mdG/Pu0f3LgFcGrl2s0kNNjpmoJ9o4B29CMO8dMT4Q5ox8uitF6fqsrJOr8qnwNbRzv6hSnG5wP+64C7h9lp30hKNtKdWjtdkbuPA19nJ7Tz3zR/ibgARbhb4AlhavcBebmTHcFl2fvYEnW0ox9xMxKBS8btJ+KiEbq9zA4RthQXDhPa0T9TEe69gWupwc6uBUphquXgf+/FrIjweHQS4/pduMe5ERUMHUd9xv8ZR98CxkS4F2n3EUrUZ10EYNw7BWm9x1GiPssi3GgiGRDKWRYZfXlON+dfNbM+GgIwYdwAAAAASUVORK5CYII',
    iconSize: [25, 41],
    iconAnchor: [12.5, 41],
    popupAnchor: [0, -41],

})
const cookies = new Cookies();
const columns = [
    { field: "approve", name: "موافقة ", options: { width: 80, filter: true, sort: false, } },
    { field: "reject", name: "رفض ", options: { width: 80, filter: true, sort: false, } },
    { field: "location", name: "الموقع", options: { width: 100, filter: true, sort: true, } },
    { field: "desc", name: "تفاصيل ", options: { width: 80, filter: true, sort: false, } },
    { field: "date", name: "التاريخ ", options: { filter: true, sort: false, } },

    { field: "sprice", name: "سعر التوصيل ", options: { filter: true, sort: false, } },
    { field: "price", name: "السعر ", options: { filter: true, sort: false, } },
    { field: "adderss", name: "العنوان", options: { filter: true, sort: false, } },
    { field: "phone", name: "رقم الهاتف", options: { filter: true, sort: false, } },
    { field: "name", name: "اسم الزبون", options: { filter: true, sort: true, } },
    { field: "id", name: "# ", options: { width: 80, filter: true, sort: false, } },
];

class Statistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            OrderFast: [],
            Data: [],
            page: [],
            Data1: [],
            page1: [],
            Data2: [],
            page2: [],
            name: '',
            phone1: '',
            phone2: '',
            price: '',
            address: '',
            email: '',
            officename: '',
            officephone: '',
            idshipment: '',
            redirect: false,
            spinPage: true,
            activePage: 0,
            search: '',
            activetap: 'wating',
            TableData: [],
            TableIndex: [],
            button: false,
        }
        // this.socket();
    }
    getMuiTheme = () => createTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: { textAlign: 'center', fontSize: 20 },
            },
            MuiTableCell: {
                root: { textAlign: 'center', fontSize: 20 },

                // head: {  backgroundColor: "#FF0000", }
            },
        }
    })
    componentDidMount(activePage, search, limit) {
        this.setState({ spinPage: true })
        // var officeId = localStorage.getItem('officeId')
        var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };

        axios.get(host + `dashbord/orders/all?page=1&limit=10000&status=0&is_fast=1`, { headers: header })
            .then(res => {
                let arr = [];
                // console.log(res.data.data.my_orders);
                for (let i = 0; i < res.data.data.my_orders.length; i++) {

                    let obj = {
                        id: res.data.data.my_orders[i].id,
                        name: res.data.data.my_orders[i].name,
                        phone: res.data.data.my_orders[i].phone,
                        adderss: res.data.data.my_orders[i].government + ' / ' + res.data.data.my_orders[i].city,
                        price: res.data.data.my_orders[i].price,
                        sprice: res.data.data.my_orders[i].shpping_price,
                        date: moment(res.data.data.my_orders[i].createdAt).tz('Asia/baghdad').format('YYYY/MM/DD'),
                        desc: <Link to={`/OrderInfo/${res.data.data.my_orders[i].id}`} >
                            <InfoIcon style={{ color: '#e86018' }} />
                        </Link>,
                        location: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        onCloseComplete={() => { setState({ isShown: false }) }}
                                        hasFooter={false}
                                        hasHeader={false}
                                    >
                                        <div>
                                            <TelegramShareButton url={`https://www.google.com/maps/search/?api=1&query=${[res.data.data.my_orders[i].latitude, res.data.data.my_orders[i].longitude]}`} >
                                                <TelegramIcon size={32} style={{ margin: 10 }} round={true} />
                                            </TelegramShareButton>
                                            <WhatsappShareButton url={`https://www.google.com/maps/search/?api=1&query=${[res.data.data.my_orders[i].latitude, res.data.data.my_orders[i].longitude]}`} >
                                                <WhatsappIcon size={32} style={{ margin: 10 }} round={true} />
                                            </WhatsappShareButton>
                                            <Map className='mapDescPage' center={[res.data.data.my_orders[i].latitude, res.data.data.my_orders[i].longitude]} zoom={12}>
                                                <TileLayer
                                                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                                <Marker
                                                    position={[res.data.data.my_orders[i].latitude, res.data.data.my_orders[i].longitude]}
                                                    icon={myIcon}>
                                                </Marker>
                                            </Map>
                                        </div>
                                    </Dialog>
                                    <LocationOnIcon onClick={() => setState({ isShown: true })}
                                        style={{ color: '#009EDD', cursor: 'pointer' }} />
                                </Pane>
                            )}
                        </Component>,
                        reject: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="رفض الطلب"
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="تاكيد"
                                        cancelLabel="الغاء"
                                        onConfirm={() => { this.cahngeStatus(res.data.data.my_orders[i].id, 2) }}
                                    >
                                        هل انت متاكد من عملية رفض الطلب
                                    </Dialog>
                                    <CancelIcon style={{ color: '#d26a74', cursor: 'pointer' }} onClick={() => setState({ isShown: true })} />
                                </Pane>
                            )}
                        </Component>,
                        approve: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="استلام الطلب"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="موافقه"
                                        cancelLabel="الغاء"
                                        onConfirm={() => { this.cahngeStatus(res.data.data.my_orders[i].id, 1) }}
                                    >
                                        هل انت متاكد من عملية استلام الطلب
                                    </Dialog>
                                    <CheckCircleIcon style={{ color: '#6ad287', cursor: 'pointer' }} onClick={() => setState({ isShown: true })} />
                                </Pane>
                            )}
                        </Component>,

                    };
                    arr.push(obj);
                }
                this.setState({
                    OrderFast: arr,
                    spinPage: false
                });
            })
            .catch(error => {
                console.log(error.response)
            })

            

        axios.get(host + `dashbord/orders/all?status=1&is_fast=1&search=${search ? search : ''}&limit=${limit ? limit : 20}&page=${activePage ? activePage : 1}`, { headers: header })
            .then(res => {
                console.log(res.data.data.my_orders);
                this.setState({
                    Data1: res.data.data.my_orders, spinPage: false,
                    page1: res.data.data
                });
            })
            .catch(error => {
                console.log(error.response)
            })

        axios.get(host + `dashbord/orders/all?status=2&is_fast=1&search=${search ? search : ''}&limit=${limit ? limit : 20}&page=${activePage ? activePage : 1}`, { headers: header })
            .then(res => {
                // console.log(res.data.data.my_orders);
                this.setState({
                    Data2: res.data.data.my_orders, spinPage: false,
                    page2: res.data.data
                });
            })
            .catch(error => {
                console.log(error.response)
            })
    }

    handlePageClick(pageNumber) {
        this.setState({ activePage: pageNumber.selected })
        this.componentDidMount(pageNumber.selected + 1)

    }
    rowSelect(data) {
        var Data = data.data;
        var row = data.rowsSelected;
        this.setState({
            TableIndex: row,
            TableData: Data,
            button: true,
        })
    }

    cahngeStatus(id, value) {
        axios.post(host + `dashbord/orders/accept/${id}`,
            qs.stringify({ status: value }), {
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") }
        }).then(response => {
            this.setState({ nameCate: '', BtnAdd: false })
            toast('تم تغيير حالة الطلب بنجاح', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            this.componentDidMount()
        }).catch(error => { console.log(error) })
    }
    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    return (
                        <div >

                            {!this.state.spinPage ?
                                <div id='tableAdminContiner' >
                                    <Paper className={'classesroot'} >
                                        <Tabs defaultActiveKey={this.state.activetap} id="uncontrolled-tab-example" style={{ backgroundColor: '#fff', }}>

                                            <Tab eventKey='wating' title="طلبات قيد الانتظار" tabClassName='wating' onClick={() => this.setState({ activetap: 'wating' })}>
                                                <div>
                                                    {this.state.button ?
                                                        <div id='btnHeaderContiner' style={{ margin: 20 }}>
                                                            <Component initialState={{ isShown: false,  data: [], indexes: [] }}>
                                                                {({ state, setState }) => (
                                                                    <Pane>
                                                                        <Dialog
                                                                            isShown={state.isShown}
                                                                            title="استلام الطلبات"
                                                                            onCloseComplete={() => setState({ isShown: false })}
                                                                            confirmLabel="موافقه"
                                                                            cancelLabel="الغاء"
                                                                            onConfirm={() => {
                                                                                let indexes = state.indexes;
                                                                                let data = state.data
                                                                                let arr = []
                                                                                setState({ isShown: false })

                                                                                for (let index = 0; index < indexes.length; index++) {
                                                                                    arr.push(data[`${indexes[index]}`].data.id)
                                                                                }
                                                                                console.log(arr);
                                                                                axios.post(host + `dashbord/orders/many/`,
                                                                                    qs.stringify({ order_ids: arr }), {
                                                                                    headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") }
                                                                                }).then(response => {
                                                                                    toast('تم تغيير حالة الطلبات بنجاح', {
                                                                                        position: "bottom-center",
                                                                                        autoClose: 5000,
                                                                                        hideProgressBar: false,
                                                                                        closeOnClick: true,
                                                                                        pauseOnHover: true,
                                                                                        draggable: true
                                                                                    });
                                                                                    this.componentDidMount()
                                                                                }).catch(error => { console.log(error) })
                                                                            }}
                                                                        >
                                                                            هل انت متاكد من عملية استلام الطلبات
                                                                        </Dialog>
                                                                        <div id='BtnAddUser' style={{ width: '100%' }} onClick={() =>{ setState({ isShown: true,data:this.state.TableData,indexes:this.state.TableIndex }) }}>موافقه على الطلبات</div>
                                                                    </Pane>


                                                                )}
                                                            </Component>

                                                        </div>
                                                        : null}
                                                    <div className='DataTableContiner'  >
                                                        <MuiThemeProvider
                                                            theme={this.getMuiTheme()}>
                                                            <MaterialDatatable data={this.state.OrderFast} columns={columns} options={{
                                                                responsive: 'scroll',
                                                                rowCursorHand: false,
                                                                sort: false,
                                                                filter: false,
                                                                print: true,
                                                                download: false,
                                                                onRowsDelete: false,
                                                                rowsPerPage: 100,
                                                                rowsPerPageOptions: [100, 200, 500],
                                                                viewColumns: false,
                                                                search: false,
                                                                showSelectedRowsToolbar: true,
                                                                onTableChange: (action, tableState) => {
                                                                    // console.log(tableState);
                                                                    this.rowSelect(tableState)
                                                                }
                                                            }} />
                                                        </MuiThemeProvider>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="done" title="طلبات تم انجازها " tabClassName='done' onClick={() => this.setState({ activetap: 'done' })}>
                                                <div id='headSerachLimit'>
                                                    <div style={{ display: 'flex' }}>
                                                        <SearchInput placeholder="بحث" style={{ paddingRight: 30 }}
                                                            value={this.state.search} onChange={e => {
                                                                this.setState({ search: e.target.value })
                                                            }} />
                                                        <div id='SEArchBTn' onClick={() => { this.componentDidMount(1, this.state.search) }}>بحث</div>

                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <label style={{ marginLeft: 10, marginTop: 10 }}>عدد الصفوف</label>
                                                        <select className='InputTExtDash111' style={{ height: 30, width: 60, paddingRight: 5, paddingLeft: 5, textAlign: 'left' }}
                                                            name='city' onChange={(e) => {
                                                                if (e.target.value !== 'limit') {
                                                                    this.componentDidMount(1, '', e.target.value)
                                                                }
                                                            }}>
                                                            <option value={20}>20</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>
                                                        <div className='InputTExtDash111NN' style={{ height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            {this.state.page1.totalItems}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='DataTableContiner' style={{ direction: 'rtl' }} >
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr style={{ textAlign: 'center' }}>
                                                                <th>#</th>
                                                                <th>الاسم</th>
                                                                <th>الهاتف</th>
                                                                <th>العنوان</th>
                                                                <th>السعر</th>
                                                                <th>سعر التوصيل</th>
                                                                <th>التاريخ</th>
                                                                <th>الموقع</th>
                                                                <th>التفاصيل</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.Data1.map((data, i) =>
                                                                <tr key={i} style={{ textAlign: 'center' }}>
                                                                    <td>{data.id}</td>
                                                                    <td>{data.name}</td>
                                                                    <td>{data.phone}</td>
                                                                    <td>{data.government + ' / ' + data.city}</td>
                                                                    <td>{data.price}</td>
                                                                    <td>{data.shpping_price}</td>
                                                                    <td>{moment(data.createdAt).tz('Asia/baghdad').format('YYYY/MM/DD LT')}</td>
                                                                    <td>   <Component initialState={{ isShown: false }}>
                                                                        {({ state, setState }) => (
                                                                            <Pane>
                                                                                <Dialog
                                                                                    isShown={state.isShown}
                                                                                    onCloseComplete={() => { setState({ isShown: false }) }}
                                                                                    hasFooter={false}
                                                                                    hasHeader={false}
                                                                                >
                                                                                    <div>
                                                                                        <TelegramShareButton url={`https://www.google.com/maps/search/?api=1&query=${[data.latitude, data.longitude]}`} >
                                                                                            <TelegramIcon size={32} style={{ margin: 10 }} round={true} />
                                                                                        </TelegramShareButton>
                                                                                        <WhatsappShareButton url={`https://www.google.com/maps/search/?api=1&query=${[data.latitude, data.longitude]}`} >
                                                                                            <WhatsappIcon size={32} style={{ margin: 10 }} round={true} />
                                                                                        </WhatsappShareButton>
                                                                                        <Map className='mapDescPage' center={[data.latitude, data.longitude]} zoom={12}>
                                                                                            <TileLayer
                                                                                                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                                                            />
                                                                                            <Marker
                                                                                                position={[data.latitude, data.longitude]}
                                                                                                icon={myIcon}>
                                                                                            </Marker>
                                                                                        </Map>
                                                                                    </div>
                                                                                </Dialog>
                                                                                <LocationOnIcon onClick={() => setState({ isShown: true })}
                                                                                    style={{ color: '#009EDD', cursor: 'pointer' }} />
                                                                            </Pane>
                                                                        )}
                                                                    </Component></td>
                                                                    <td><Link to={`/OrderInfo/${data.id}`} >
                                                                        <InfoIcon style={{ color: '#e86018' }} />
                                                                    </Link></td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>

                                                </div>
                                                <div id='pagnationContiner'>
                                                    <ReactPaginate
                                                        previousLabel={'السابق'}
                                                        nextLabel={'التالي'}
                                                        breakLabel={'...'}
                                                        breakClassName={'break-me'}
                                                        pageCount={this.state.page1.totalpages}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={8}
                                                        forcePage={this.state.activePage}
                                                        onPageChange={(page) => this.handlePageClick(page)}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                        activeClassName={'active'}
                                                    />
                                                </div>
                                            </Tab>
                                            <Tab eventKey="cancel" title="طلبات تم الغاؤها " tabClassName='cancel' onClick={() => this.setState({ activetap: 'cancel' })}>
                                                <div id='headSerachLimit'>
                                                    <div style={{ display: 'flex' }}>
                                                        <SearchInput placeholder="بحث" style={{ paddingRight: 30 }}
                                                            value={this.state.search} onChange={e => {
                                                                this.setState({ search: e.target.value })
                                                            }} />
                                                        <div id='SEArchBTn' onClick={() => { this.componentDidMount(1, this.state.search) }}>بحث</div>

                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <label style={{ marginLeft: 10, marginTop: 10 }}>عدد الصفوف</label>
                                                        <select className='InputTExtDash111' style={{ height: 30, width: 60, paddingRight: 5, paddingLeft: 5, textAlign: 'left' }}
                                                            name='city' onChange={(e) => {
                                                                if (e.target.value !== 'limit') {
                                                                    this.componentDidMount(1, '', e.target.value)
                                                                }
                                                            }}>
                                                            <option value={20}>20</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>
                                                        <div className='InputTExtDash111NN' style={{ height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            {this.state.page2.totalItems}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='DataTableContiner' style={{ direction: 'rtl' }} >
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr style={{ textAlign: 'center' }}>
                                                                <th>#</th>
                                                                <th>الاسم</th>
                                                                <th>الهاتف</th>
                                                                <th>العنوان</th>
                                                                <th>السعر</th>
                                                                <th>سعر التوصيل</th>
                                                                {/* <th>بروموكود</th> */}
                                                                <th>التاريخ</th>
                                                                <th>التفاصيل</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.Data2.map((data, i) =>
                                                                <tr key={i} style={{ textAlign: 'center' }}>
                                                                    <td>{data.id}</td>
                                                                    <td>{data.name}</td>
                                                                    <td>{data.phone}</td>
                                                                    <td>{data.government + ' / ' + data.city}</td>
                                                                    <td>{data.price}</td>
                                                                    <td>{data.shpping_price}</td>
                                                                    {/* <td>{data.promo}</td> */}
                                                                    <td>{moment(data.createdAt).tz('Asia/baghdad').format('YYYY/MM/DD LT')}</td>
                                                                    <td><Link to={`/OrderInfo/${data.id}`} >
                                                                        <InfoIcon style={{ color: '#e86018' }} />
                                                                    </Link></td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>

                                                </div>
                                                <div id='pagnationContiner'>
                                                    <ReactPaginate
                                                        previousLabel={'السابق'}
                                                        nextLabel={'التالي'}
                                                        breakLabel={'...'}
                                                        breakClassName={'break-me'}
                                                        pageCount={this.state.page2.totalpages}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={8}
                                                        forcePage={this.state.activePage}
                                                        onPageChange={(page) => this.handlePageClick(page)}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                        activeClassName={'active'}
                                                    />
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </Paper>
                                    <ToastContainer
                                        position="bottom-left"
                                        autoClose={10000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                                : <div style={{ width: '100%', height: 'calc(100vh - 100px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Spinner />
                                </div>
                            }

                        </div>
                    )
                }}
            </Context.Consumer>
        )
    }
}
export default Statistics;