import React from 'react';
import Component from "@reactions/component";
import { Dialog, Pane, Spinner } from 'evergreen-ui';
import DeleteIcon from '@material-ui/icons/Delete';
import Cookies from "universal-cookie";
import host from '../../../assets/js/Host';
import axios from 'axios';
// import Lottie from 'lottie-react-web'
// import animation from '../../assets/json/bee.json';
import { Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import FilerobotImageEditor from 'filerobot-image-editor';
// import imageCompression from 'browser-image-compression';


const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            banner: [],
            spin: true,
            BtnAdd: false,
            image: [],
            event: false,
            text: ''
        }
    }
    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/banners/`, { headers: header })
            .then(res => {
                // console.log(res.data.data.banners);
                this.setState({ banner: res.data.data.banners, spin: false });
            })
            .catch(error => { console.log(error) })
    }
    AddBanner() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("file", this.state.image[0]);
        axios({ url: host + `dashbord/banners`, method: "POST", data: formData, headers: header })
            .then(response => {
                this.setState({ image: '', nameCate: '', BtnAdd: false })
                toast('تمت اضافة الاعلان بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount()
            })
            .catch(error => { console.log(error.reasponse.data.message) })
    }
    deletBanner(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.delete(host + `dashbord/banners/${id}`, { headers: header })
            .then(response => {
                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }

    render() {
        // if (this.state.spin) {
        return (
            <div>
                {!this.state.spin ?
                    <div>
                        <div id='categoryContentContinerFirst' style={this.state.BtnAdd ? { display: 'none' } : { display: 'flex' }}>
                            <div id='btnADDCateGory' onClick={() => this.setState({ BtnAdd: true })}>اضافة اعلان</div>
                        </div>
                        <div className='categoryContentContiner' style={this.state.BtnAdd ? { display: 'flex' } : { display: 'none' }}>
                            <Row style={{ margin: 0, width: '100%' }}>
                                <Col id='colCateGOry' sm={12} md={6} >
                                    <div id={'ContinerInPut'}>
                                        <label style={{ marginLeft: 20 }}>صورة</label>
                                        <input type="file" name="profilePicBtn" id='InputTExtDash111Image' accept="image/png, image/jpeg" style={{ height: 30 }}
                                            onChange={(event) => { this.setState({ image: event.target.files }) }} />

                                    </div>
                                </Col>
                                <Col id='colCateGOry1' sm={12} md={6} >
                                    <div id='btnADDCateGory' style={{ marginLeft: 15 }}
                                        onClick={() => {
                                            this.AddBanner()
                                        }}>اضافة </div>
                                    <div id='btnADDCateGory' style={{ marginRight: 15, backgroundColor: '#ed818b' }}
                                        onClick={() => this.setState({ BtnAdd: false })}>الغاء</div>
                                </Col>

                            </Row>

                        </div>
                        <Row style={{ margin: 0, padding: 0, direction: 'rtl' }}>
                            {this.state.banner.map(bann =>
                                <Col key={bann.id} id='colbanner' sm={12} md={6} lg={4} xl={3}>
                                    <div id='BannerContinerDash'>
                                        <img src={host + 'files/' + bann.photo} alt='banner' id='imgBannerDash' />
                                        <Component initialState={{ isShown: false }}>
                                            {({ state, setState }) => (
                                                <Pane>
                                                    <Dialog
                                                        isShown={state.isShown}
                                                        title="حذف الاعلان "
                                                        intent="danger"
                                                        onCloseComplete={() => setState({ isShown: false })}
                                                        confirmLabel="حذف"
                                                        cancelLabel="الغاء"
                                                        onConfirm={() => {
                                                            setState({ isShown: false })
                                                            this.deletBanner(bann.id)
                                                        }}
                                                    >
                                                        <span id='msgDelete'> هل انت متأكد من عملية الحذف</span>
                                                    </Dialog>
                                                    <DeleteIcon id='deleteBannerDash' onClick={() => setState({ isShown: true })} />
                                                </Pane>
                                            )}
                                        </Component>

                                    </div>
                                </Col>
                            )}
                        </Row>
                        <ToastContainer
                            position="bottom-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnVisibilityChange
                            draggable
                            pauseOnHover
                        />

                    </div>
                    : <div style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Spinner />
                    </div>}
            </div>
        )
        // }
        // else {
        //     return (
        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        //             <Lottie
        //                 options={{
        //                     animationData: animation
        //                 }}
        //             />
        //         </div>
        //     )
        // }
    }
}
export default Category