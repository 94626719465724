import React from 'react';
import Context from '../../../assets/js/Context';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Component from "@reactions/component";
import { Form } from 'react-bootstrap';
// import 'cropperjs/dist/cropper.min.css';
import { Link } from 'react-router-dom';
import { Dialog, Pane, Spinner, Position, SideSheet } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from "universal-cookie";
import EditIcon from '@material-ui/icons/Edit';
import Login from '../../common/login';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import host from '../../../assets/js/Host';
import axios from 'axios';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
// window.scrollTo(0, 55555555)   

const cookies = new Cookies();
const columns = [
    { field: "delete", name: "حذف", options: { width: 50, filter: true, sort: false, } },
    { field: "edit", name: "تعديل", options: { width: 50, filter: true, sort: false, } },
    { field: "gift", name: "هدية", options: {width: 50, filter: true, sort: true, } },
    { field: "offerP", name: "سعر العرض", options: { filter: true, sort: true, } },
    { field: "count", name: "العدد", options: { filter: true, sort: true, } },
    { field: "price", name: "السعر", options: { filter: true, sort: true, } },
    { field: "name", name: "الاسم", options: { filter: true, sort: true, } },
    { field: "image", name: "صوره", options: { width: 150, filter: true, sort: false, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    filter: false,
    print: false,
    download: false,
    rowsPerPage: 100,
    textLabels: {
        body: { noMatch: " لم يتم العثور على سجلات مطابقة", toolTip: "فرز", },
        pagination: { next: "الصفحة التالية", previous: "الصفحة السابقة", rowsPerPage: "عدد الصفوف", displayRows: "من", },
        toolbar: { search: "بحث", downloadCsv: "تنزيل", print: "Print", viewColumns: " التحكم بالاعمدة", filterTable: "فلتر", },
        filter: { all: "الكل", title: "فلتر", reset: "إعادة تعيين", },
        viewColumns: { title: "عرض الأعمدة", titleAria: "إظهار / إخفاء أعمدة الجدول", },
    }
}
class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            spin: true,
            spinCate: true,
            name: '',
            cost: '',
            type: '',
            file: [],
        }

    }

    componentDidMount() {
        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.get(host + `dashbord/gifts/all`, { headers: header })
            .then(res => {
                let arr = [];
                console.log(res.data);
                for (let i = 0; i < res.data.length; i++) {

                    let obj = {
                        image: <img src={host + 'files/' + res.data[i].cove_photo} alt='img' style={{ width: 100, height: 80 }} />,
                        name: res.data[i].name,
                        price: res.data[i].price,
                        count: res.data[i].count,
                        offerP: res.data[i].offer_price,
                        gift: res.data[i].is_gift ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />,
                        edit: <Link to={`/EditItem/${res.data[i].id}`} onClick={() => {
                            localStorage.setItem('returnto', `Gifts`)
                        }}>
                            <div id='iconTableContiner' >
                                <EditIcon style={{ color: '#0c4c91', cursor: 'pointer' }} />
                            </div>
                        </Link>,
                        delete: <Component initialState={{ isShown: false, spin: true, office_ids: [] }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="حذف مادة "
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="حذف"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            var header = { "Content-Type": "application/json", token: cookies.get("token") };

                                            axios.delete(host + `items/item/${res.data[i].id}`, { headers: header })
                                                .then(response => {
                                                    toast('تم الحذف بنجاح', {
                                                        position: "bottom-center",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true
                                                    });
                                                    setState({ isShown: false })
                                                    this.componentDidMount()
                                                })
                                                .catch(error => {
                                                    console.log(error.response)
                                                });
                                        }}
                                    >
                                        هل انت متاكد من عمليه الحذف
                                    </Dialog>
                                    <div id='iconTableContiner' onClick={() => { setState({ isShown: true }) }}>
                                        <DeleteForeverIcon style={{ color: '#d87373de', cursor: 'pointer' }} />
                                    </div>
                                </Pane>
                            )}
                        </Component>,
                    };
                    arr.push(obj);
                }
                this.setState({
                    items: arr, spinCate: false
                });
            })
            .catch(error => { console.log(error) })
        //   window.addEventListener('scroll',5535.5556640625)
        //  window.pageYOffset(5535)

    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })

    render() {
        // window.scrollTo(5535.5556640625,0)
        return (
            <Context.Consumer>
                {ctx => {
                    if (cookies.get("token")) {
                        if (!this.state.spinCate) {
                            return (
                                <div style={{ direction: 'rtl' }}>
                                    <div>
                                      
                                        <div className='DataTableContiner'>
                                            <MuiThemeProvider
                                                theme={this.getMuiTheme()}>
                                                <MaterialDatatable data={this.state.items} columns={columns} options={options} />
                                            </MuiThemeProvider>
                                        </div>
                                    </div>
                                    <ToastContainer
                                        position="bottom-left"
                                        autoClose={10000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }}>
                                    <Spinner />
                                </div>
                            )
                        }
                    }
                    else {
                        return (
                            <Login />
                        )
                    }
                }
                }

            </Context.Consumer>
        )
    }
}

export default Category;