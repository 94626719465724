import React from 'react';
import Context from '../../assets/js/Context';
import { Link } from 'react-router-dom';

class Card extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {
        return (
            <Context.Consumer>
                {ctx => {

                    return (
                        <div >
                            {!this.props.chaid?
                            <Link to={this.props.link} onClick={() => {
                                var items = [{ "id": this.props.id, "name": this.props.name }];
                                localStorage.setItem('Breadcrumb', JSON.stringify(items));
                                localStorage.setItem('officeIdNow', this.props.officeId);
                            }}>
                                <div id='CardCateNewContiner'>
                                    <div id='CardCateNewImage'>
                                        <img id='CardCateNewImage' src={this.props.image} alt='img' />
                                    </div>
                                    <div id='CardCateNewTitle'>{this.props.name} </div>
                                </div>
                            </Link>
                            :
                            <Link to={this.props.link} onClick={() => {
                                var items = JSON.parse(localStorage.getItem('Breadcrumb'));
                                var item = { "id": this.props.id, "name": this.props.name }
                                items.push(item);
                                localStorage.setItem('Breadcrumb', JSON.stringify(items));
                            }}>
                                <div id='CardCateNewContiner'>
                                    <div id='CardCateNewImage'>
                                        <img id='CardCateNewImage' src={this.props.image} alt='img' />
                                    </div>
                                    <div id='CardCateNewTitle'>{this.props.name} </div>
                                </div>
                            </Link>
                }
                        </div>

                    )

                }
                }
            </Context.Consumer>
        )
    }
}


export default Card;