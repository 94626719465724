import React from 'react';
import Context from '../../../assets/js/Context';
import Component from "@reactions/component";
import { Row, Col } from 'react-bootstrap';
import { Spinner } from 'evergreen-ui';
import Cookies from "universal-cookie";
import host from '../../../assets/js/Host';
import axios from 'axios';
import CardCate from '../../common/cardCategory';
const cookies = new Cookies();
class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            category: [],
            categoryid: [],
            spin: true,
            spinCate: true,
            Bread: false,
            NameOfficeLink: localStorage.getItem('NameOfficeLink'),
        }
    }

    componentDidMount() {
        var header = { "Content-Type": "application/json", token: cookies.get("token") };
        axios.get(host + `category/mains?`, { headers: header })
            .then(response => {
                // console.log(response.data.data)

                if (response.status === 200) {
                    this.setState({
                        category: response.data.data.categories,
                        spinCate: false
                    })
                }
            })
            .catch((error) => {
                this.setState({ spinCate: false })
                console.log('error ' + error)
            })
    }


    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    if (!this.state.spinCate) {
                        return (
                            <div >
                                <Row style={{ margin: 0,direction:'rtl' }}>
                                    {this.state.category.map(cate =>
                                        <Col key={cate.id} id='ColFolder' xs={6} md={4} lg={3} xl={2} >
                                            <Component initialState={{ menuOpen: false, edit: false, isShown: false }}>
                                                {({ state, setState }) => (
                                                    <div id='FolderCateContiner'>
                                                        <CardCate id={cate.id} image={host + 'files/' + cate.photo} name={cate.name}
                                                         link={`/CateByOffice1/${cate.id}`}  chaid={false}/>
                                                    </div>
                                                )}
                                            </Component>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }}>
                                <Spinner />
                            </div>
                        )
                    }

                }
                }

            </Context.Consumer>
        )
    }
}

export default Category;