
import React from 'react';
import Context from '../../../assets/js/Context';
import Component from "@reactions/component";
import { Dialog, Pane ,Spinner} from 'evergreen-ui';
import Login from '../../common/login';
import Cookies from "universal-cookie";
import VisibilityIcon from '@material-ui/icons/Visibility';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Link } from 'react-router-dom';
import host from '../../../assets/js/Host';
import axios from 'axios';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
var Barcode = require('react-barcode');
const cookies = new Cookies();
const columns2 = [
    { field: "desc", name: "تفاصيل", options: { width: 50, filter: true, sort: false, } },
    { field: "priceOff", name: "سعر العرض", options: { filter: true, sort: true, } },
    { field: "barcode", name: "الباركود", options: { filter: true, sort: true, } },
    { field: "count", name: "العدد", options: { filter: true, sort: true, } },
    { field: "price", name: "السعر", options: { filter: true, sort: true, } },
    { field: "type", name: "النوع", options: { filter: true, sort: true, } },
    { field: "name", name: "الاسم", options: { filter: true, sort: true, } },
    { field: "image", name: "صوره", options: { width: 150, filter: true, sort: false, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    filter: false,
    print: false,
    download: false,
    rowsPerPage: 100,
    textLabels: {
        body: { noMatch: " لم يتم العثور على سجلات مطابقة", toolTip: "فرز", },
        pagination: { next: "الصفحة التالية", previous: "الصفحة السابقة", rowsPerPage: "عدد الصفوف", displayRows: "من", },
        toolbar: { search: "بحث", downloadCsv: "تنزيل", print: "Print", viewColumns: " التحكم بالاعمدة", filterTable: "فلتر", },
        filter: { all: "الكل", title: "فلتر", reset: "إعادة تعيين", },
        viewColumns: { title: "عرض الأعمدة", titleAria: "إظهار / إخفاء أعمدة الجدول", },
    }
}
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            offers: [],
            NameOfficeLink: '',
            office: [],
            wattingGetOffice: true,
            spin:true, 
        }
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: { textAlign: 'right', },
            },
            MuiTableCell: {
                root: { textAlign: 'right', },
                // head: {  backgroundColor: "#FF0000", }
            },
        }
    })
    componentDidMount() {
        var NameOfficeLink = localStorage.getItem('NameOfficeLink')
        var header = { "Content-Type": "application/json", token: cookies.get("token") };



        axios.get(host + `dashbord/office/items/offers/${this.props.match.params.id}`, { headers: header })
            .then(res => {
                let arr = [];
                // console.log(res.data.data.items);
                for (let i = 0; i < res.data.data.items.length; i++) {

                    let obj = {
                        image: <img src={host + 'files/' + res.data.data.items[i].photo} alt='img' style={{ width: 100, height: 80 }} />,
                        name: res.data.data.items[i].name,
                        type: res.data.data.items[i].type === 0 ? 'عدد' : 'وزن',
                        price: res.data.data.items[i].price,
                        count: res.data.data.items[i].count,
                        barcode: res.data.data.items[i].barcode,
                        priceOff: res.data.data.items[i].offer_price === 0 ? 'لايوجد' : res.data.data.items[i].offer_price,
                        desc: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        onCloseComplete={() => setState({ isShown: false })}
                                        hasFooter={false}
                                        hasHeader={false}
                                    >
                                        <div style={{ textAlign: 'right', width: '100%' }}>
                                            <p>{res.data.data.items[i].description}</p>
                                            <Barcode value={res.data.data.items[i].barcode} />
                                        </div>
                                    </Dialog>

                                    <div id='iconTableContiner' onClick={() => setState({ isShown: true })}>
                                        <VisibilityIcon style={{ color: '#0c4c91', cursor: 'pointer' }}
                                        /></div>
                                </Pane>
                            )}
                        </Component>,
                    };
                    arr.push(obj);
                }
                this.setState({
                    offers: arr, NameOfficeLink,spin:false 
                });
            })
            .catch(error => { console.log(error.response) 
                this.setState({spin:false });
            })
    }

    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    if (cookies.get("token")) {
                        if (!this.state.spin) {
                        return (
                            <div>
                                 <div id='navAddItem'>
                                    <Link to={`/OfficeOption/${this.props.match.params.id}`}>
                                        <KeyboardBackspaceIcon style={{ cursor: 'pointer', height: 35, width: 35, color: '#fff' }} />
                                    </Link>
                                    <div>فرع  {this.state.NameOfficeLink} / العروض</div>
                                </div>
                                <div className='DataTableContiner'>
                                    <MuiThemeProvider
                                        theme={this.getMuiTheme()}>
                                        <MaterialDatatable data={this.state.offers} columns={columns2} options={options} />
                                    </MuiThemeProvider>
                                </div>

                            </div>
                        )
                    }
                    else {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }}>
                                <Spinner />
                            </div>
                        )
                    }
                    }


                    else {
                        return (
                            <Login />
                        )
                    }
                }
                }

            </Context.Consumer>
        )
    }
}

export default Home;