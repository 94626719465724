import React from 'react';
import Context from '../../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../../assets/js/Host';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'evergreen-ui'
import { Col, Row } from 'react-bootstrap';
import qs from 'qs';
// import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment-timezone";
import Login from '../../common/login';

const cookies = new Cookies();

class Items extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            spin: false,
            order: [],
            info: [],
            data: [],
            phoneOrder: '',
            nameOrder: '',
            statusOrder: '',
            driverOption: [],
            idDriverSelect: '',
            sendTOdriver: false,
            sendTOonProcess: false,
            idOrder: '',
            DateRecive: '',
            DateRecive1: '',
            spinBtn1: false,
            spinBtn2: false,
            latitude: '',
            longitude: '',
            totalprice: '',
            spinPage: true,
        }
    }

    componentDidMount() {

        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
        axios.get(host + `dashbord/services/orders/order/${this.props.match.params.id}`, { headers: header })
            .then(res => {
                console.log(res.data.data.order);
                this.setState({
                    order: res.data.data.order, spinPage: false,
                })

            })
            .catch(error => { console.log(error) })
    }
    cahngeStatus(value) {
        axios.put(host + `orders/orders/${this.props.match.params.id}`,
            qs.stringify({ status: value }), {
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") }
        }).then(response => {
            toast('تم تغيير حالة الطلب بنجاح', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            this.setState({ spinBtn1: false, spinBtn2: false })
            localStorage.setItem('approveOrder', 'false')
            this.componentDidMount()
        }).catch(error => {
            this.setState({ spinBtn1: false, spinBtn2: false })
            console.log(error)
        })
    }

    render() {

        return (
            <Context.Consumer>
                {ctx => {
                    if (cookies.get("token")) {
                        if (!this.state.spinPage) {
                            return (
                                <div >
                                    <Row style={{ direction: 'rtl', padding: 0, margin: 10, marginTop: 20 }}>
                                        <Col sm={6} md={3} lg={2} id='colOrderBy'>
                                            <div id='textDescPagelabel'>رقم الطلب </div>
                                            <div id='textDescPagecontent'>{this.state.order.id}</div>
                                        </Col>
                                        <Col sm={6} md={3} lg={2} id='colOrderBy'>
                                            <div id='textDescPagelabel'>الاسم </div>
                                            <div id='textDescPagecontent'>{this.state.order.name}</div>
                                        </Col>
                                        <Col sm={6} md={3} lg={2} id='colOrderBy'>
                                            <div id='textDescPagelabel'>رقم الهاتف </div>
                                            <div id='textDescPagecontent'>{this.state.order.phone}</div>
                                        </Col>
                                        <Col sm={6} md={3} lg={2} id='colOrderBy'>
                                            <div id='textDescPagelabel'>العنوان </div>
                                            <div id='textDescPagecontent'>{this.state.order.address}</div>
                                        </Col>
                                        <Col sm={6} md={3} lg={2} id='colOrderBy'>
                                            <div id='textDescPagelabel'>المبلغ الكلي </div>
                                            <div id='textDescPagecontent'>{this.state.order.price}</div>
                                        </Col>
                                        <Col sm={6} md={3} lg={2} id='colOrderBy'>

                                            <div id='textDescPagelabel'>التاريخ </div>
                                            <div id='textDescPagecontent'>{moment(this.state.order.createdAt).tz('Asia/baghdad').format('YYYY/MM/DD LT')}</div>
                                        </Col>
                                    </Row>
                                    <h4 className='OrderSByILabel'>ملاحظات</h4>
                                    <div className='OrderSByINote'>
                                        {this.state.order.notes}
                                    </div>
                                    <h4 className='OrderSByILabel'>معلومات الحيوان</h4>
                                    <div className='OrderSByIPet'>
                                        <img src={host + 'files/' + this.state.order.pet.photo} alt='img' style={{ width: 150, height: 150 }} />
                                       <div style={{ width:'70%'}}>
                                       <Row style={{ width:'100%', padding: 0, margin: 0 }}>
                                            <Col sm={6}  id='colOrderBy'>
                                                <div id='petContint'>
                                                    <h5>النوع </h5>
                                                    <h6> {this.state.order.pet.type}</h6>
                                                </div>
                                            </Col>
                                            <Col sm={6}  id='colOrderBy'>
                                                <div id='petContint'>
                                                    <h5>الاسم </h5>
                                                    <h6> {this.state.order.pet.name}</h6>
                                                </div>
                                            </Col>
                                            <Col sm={6}  id='colOrderBy'>
                                                <div id='petContint'>
                                                    <h5>الجنس  </h5>
                                                    <h6> {this.state.order.pet.sex}</h6>
                                                </div>
                                            </Col>
                                            <Col sm={6}  id='colOrderBy'>
                                                <div id='petContint'>
                                                    <h5>العمر </h5>
                                                    <h6>{moment(this.state.order.pet.age).format('YYYY/MM/DD')}</h6>
                                                </div>
                                            </Col>
                                            <Col sm={6}  id='colOrderBy'>
                                                <div id='petContint'>
                                                    <h5>ملاحظات </h5>
                                                    <h6> {this.state.order.pet.description}</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                  
                                       </div>
                                         </div>
                                    <h4 className='OrderSByILabel'>معلومات الخدمه</h4>
                                    <div className='OrderSByIPet'>
                                        <img src={host + 'files/' + this.state.order.services.photo} alt='img' style={{ width: 150, height: 150 }} />
                                       <div style={{ width:'70%'}}>
                                       <Row style={{ width:'100%', padding: 0, margin: 0 }}>
                                            <Col sm={6}  id='colOrderBy'>
                                                <div id='petContint'>
                                                    <h5>النوع </h5>
                                                    <h6> {this.state.order.services.in_house}</h6>
                                                </div>
                                            </Col>
                                            <Col sm={6}  id='colOrderBy'>
                                                <div id='petContint'>
                                                    <h5>المبلغ </h5>
                                                    <h6> {this.state.order.services.cost}</h6>
                                                </div>
                                            </Col>
                                           
                                        </Row>
                                  
                                       </div>
                                         </div>
                                
                                    <ToastContainer
                                        position="bottom-left"
                                        autoClose={10000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                            )
                        } else {
                            return (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }}>
                                    <Spinner />
                                </div>
                            )
                        }
                    }
                    else {
                        return (
                            <Login />
                        )
                    }
                }
                }
            </Context.Consumer>
        )
    }
}


export default Items;