
import React from 'react';
import Context from '../../../assets/js/Context';
import { Row, Col } from 'react-bootstrap';
import Login from '../../common/login';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
// import ListAltIcon from '@material-ui/icons/ListAlt';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import PageviewIcon from '@material-ui/icons/Pageview';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Link } from 'react-router-dom';
import Cookies from "universal-cookie";
const cookies = new Cookies();

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Activeitems: [],
            unActiveitems: [],
            offers: [],
            NameOfficeLink: localStorage.getItem('NameOfficeLink'),
            office: [],
            wattingGetOffice: true,
        }
    }

    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    if (cookies.get("token")) {
                        return (
                            <div>
                               <div id='UpeerOfficeOption'>
                               <div id='navAddItem'>
                                    <Link to={`/Office`}>
                                        <KeyboardBackspaceIcon style={{ cursor: 'pointer', height: 35, width: 35, color: '#fff' }} />
                                    </Link>
                                    <div>فرع ( {this.state.NameOfficeLink} )</div>
                                </div>
                                <Row style={{ margin: 0, direction: 'rtl' }}>
                                    <Col id='ColOptin' sm={12} md={6} lg={4} xl={3} >
                                        <Link to={`/CateByOffice/${this.props.match.params.id}`}>
                                            <div id='CardOption0' >
                                                <div style={{ textAlign: 'right' }}>
                                                    <h6>تصفح المواد</h6>
                                                </div>
                                                <DynamicFeedIcon style={{ width: 40, height: 40 }} />
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col id='ColOptin' sm={12} md={6} lg={4} xl={3} >
                                        <Link to={`/ActiveItem/${this.props.match.params.id}`}>
                                            <div id='CardOption1' >
                                                <div style={{ textAlign: 'right' }}>
                                                    <h6>المواد الفعالة</h6>
                                                </div>
                                                <AssignmentTurnedInIcon style={{ width: 40, height: 40 }} />
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col id='ColOptin' sm={12} md={6} lg={4} xl={3} >
                                        <Link to={`/UnActiveItem/${this.props.match.params.id}`}>
                                            <div id='CardOption2' >
                                                <div style={{ textAlign: 'right' }}>
                                                    <h6>المواد المحجوبة</h6>
                                                </div>
                                                <AssignmentLateIcon style={{ width: 40, height: 40 }} />
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col id='ColOptin' sm={12} md={6} lg={4} xl={3} >
                                        <Link to={`/OffersItem/${this.props.match.params.id}`}>
                                            <div id='CardOption3' >
                                                <div style={{ textAlign: 'right' }}>
                                                    <h6>العروض</h6>
                                                </div>
                                                <LocalOfferIcon style={{ width: 40, height: 40 }} />
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col id='ColOptin' sm={12} md={6} lg={4} xl={3} >
                                        <Link to={`/ItemCount/${this.props.match.params.id}`}>
                                            <div id='CardOption4' >
                                                <div style={{ textAlign: 'right' }}>
                                                    <h6>قريبة النفاذ</h6>
                                                </div>
                                                <PageviewIcon style={{ width: 40, height: 40 }} />
                                            </div>
                                        </Link>
                                    </Col>
                                    {/* <Col id='ColOptin' sm={12} md={6} lg={4} xl={3} >
                                        <div id='CardOption5' >
                                            <div style={{ textAlign: 'right' }}>
                                                <h6>عدد الطلبات</h6>
                                                <h6>66</h6>
                                            </div>
                                            <ListAltIcon style={{ width: 40, height: 40 }} />
                                        </div>
                                    </Col> */}
                                </Row>
                      
                               </div>
                     
                            </div>
                        )
                    }


                    else {
                        return (
                            <Login />
                        )
                    }
                }
                }

            </Context.Consumer>
        )
    }
}

export default Home;