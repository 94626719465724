import React from 'react';
import Context from '../../../assets/js/Context';
import Component from "@reactions/component";
import { Spinner, SearchInput, Pane, Dialog } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Key from '@material-ui/icons/VpnKey';
import qs from 'qs';
// import Component from "@reactions/component";
import Cookies from "universal-cookie";
import host from '../../../assets/js/Host';
import axios from 'axios';
import { Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
const cookies = new Cookies();
class Statistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            page: [],
            Data1: [],
            page1: [],
            Data2: [],
            page2: [],
            name: '',
            phone1: '',
            phone2: '',
            price: '',
            address: '',
            email: '',
            officename: '',
            officephone: '',
            idshipment: '',
            redirect: false,
            spinPage: true,
            activePage: 0,
            search: '',
            activetap: 'wating'
        }
        // this.socket();
    }
    componentDidMount(activePage, search, limit) {
        this.setState({ spinPage: true })
        // var officeId = localStorage.getItem('officeId')
        var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };

        axios.get(host + `dashbord/users/all?status=0&search=${search ? search : ''}&limit=${limit ? limit : 20}&page=${activePage ? activePage : 1}`, { headers: header })
            .then(res => {
                console.log(res.data.data.users);
                this.setState({
                    Data: res.data.data.users, spinPage: false,
                    page: res.data.data
                });
            })
            .catch(error => {
                console.log(error.response)
            })

    }

    handlePageClick(pageNumber) {
        this.setState({ activePage: pageNumber.selected })
        this.componentDidMount(pageNumber.selected + 1)

    }


    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    return (
                        <div >

                            {!this.state.spinPage ?
                                <div>
                                    <div id='headSerachLimit'>
                                        <div style={{ display: 'flex' }}>
                                            <SearchInput placeholder="بحث" style={{ paddingRight: 30 }}
                                                value={this.state.search} onChange={e => {
                                                    this.setState({ search: e.target.value })
                                                }} />
                                            <div id='SEArchBTn' onClick={() => { this.componentDidMount(1, this.state.search) }}>بحث</div>

                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <label style={{ marginLeft: 10, marginTop: 10 }}>عدد الصفوف</label>
                                            <select className='InputTExtDash111' style={{ height: 30, width: 60, paddingRight: 5, paddingLeft: 5, textAlign: 'left' }}
                                                name='city' onChange={(e) => {
                                                    if (e.target.value !== 'limit') {
                                                        this.componentDidMount(1, '', e.target.value)
                                                    }
                                                }}>
                                                <option value={20}>20</option>
                                                <option value={50}>50</option>
                                                <option value={100}>100</option>
                                            </select>
                                            <div className='InputTExtDash111NN' style={{ height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                {this.state.page.totalItems}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='DataTableContiner' style={{ direction: 'rtl' }} >
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr style={{ textAlign: 'center' }}>
                                                    <th>#</th>
                                                    <th>الاسم</th>
                                                    <th>الهاتف</th>
                                                    <th>عدد الطلبات</th>
                                                    <th>كلمه المرور</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.Data.map((data, i) =>
                                                    <tr key={i} style={{ textAlign: 'center' }}>
                                                        <td>{data.id}</td>
                                                        <td>{data.name}</td>
                                                        <td>{data.phone}</td>
                                                        <td>{data.count}</td>

                                                        <td><Component initialState={{ isShown: false,New:'' }}>
                                                            {({ state, setState }) => (
                                                                <Pane>
                                                                    <Dialog
                                                                        isShown={state.isShown}
                                                                        title={"("+data.name+") تغيير كلمه المرور"}
                                                                        onCloseComplete={() => setState({ isShown: false })}
                                                                        confirmLabel="تاكيد"
                                                                        cancelLabel="الغاء"
                                                                        onConfirm={() => {
                                                                            axios.post(host + `dashbord/users/${data.id}`,
                                                                                qs.stringify({
                                                                                    password: state.New
                                                                                }), {
                                                                                headers: { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") }
                                                                            }).then(response => {
                                                                                setState({ isShown: false })
                                                                                this.componentDidMount()
                                                                                toast("تم تغيير كلمه المرور", {
                                                                                    position: "bottom-center",
                                                                                    autoClose: 5000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true
                                                                                });
                                                                            }).catch(error => {
                                                                                this.setState({ spinnerBtn: false })
                                                                                console.log(error.response.data)
                                                                            })
                                                                        }}
                                                                    >
                                                                        <div id={'ContinerInPut'} >
                                                                            <label> كلمه المرور الجديده </label>
                                                                            <input autoComplete='off' type='password' placeholder='كلمه المرور الجديده' id='InputTExtDash111' style={{ height: 32, width: 250 }}
                                                                                onChange={(e) => setState({ New: e.target.value })} />
                                                                        </div>
                                                                    </Dialog>
                                                                    <Key style={{ color: '#6ad287', cursor: 'pointer' }} onClick={() => setState({ isShown: true })} />
                                                                </Pane>
                                                            )}
                                                        </Component>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>

                                    </div>
                                    <div id='pagnationContiner'>
                                        <ReactPaginate
                                            previousLabel={'السابق'}
                                            nextLabel={'التالي'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={this.state.page.totalpages}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={8}
                                            forcePage={this.state.activePage}
                                            onPageChange={(page) => this.handlePageClick(page)}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </div>
                                    <ToastContainer
                                        position="bottom-left"
                                        autoClose={10000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                                : <div style={{ width: '100%', height: 'calc(100vh - 100px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Spinner />
                                </div>
                            }

                        </div>
                    )
                }}
            </Context.Consumer>
        )
    }
}
export default Statistics;