
import React from 'react';
import Context from '../../../assets/js/Context';
import Component from "@reactions/component";
import { Row, Col } from 'react-bootstrap';
import { Dialog, Pane, Spinner } from 'evergreen-ui';
import Login from '../../common/login';
import Cookies from "universal-cookie";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import host from '../../../assets/js/Host';
import axios from 'axios';
import qs from 'qs';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
var Barcode = require('react-barcode');
const cookies = new Cookies();

const columns1 = [
    { field: "delete", name: "حذف", options: { width: 50, filter: true, sort: false, } },
    { field: "active", name: "تفعيل", options: { width: 50, filter: true, sort: false, } },
    { field: "edit", name: "تعديل", options: { width: 50, filter: true, sort: false, } },
    { field: "desc", name: "تفاصيل", options: { width: 50, filter: true, sort: false, } },
    { field: "barcode", name: "الباركود", options: { filter: true, sort: true, } },
    { field: "count", name: "العدد", options: { filter: true, sort: true, } },
    { field: "price", name: "السعر", options: { filter: true, sort: true, } },
    { field: "type", name: "النوع", options: { filter: true, sort: true, } },
    { field: "name", name: "الاسم", options: { filter: true, sort: true, } },
    { field: "image", name: "صوره", options: { width: 150, filter: true, sort: false, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    filter: false,
    print: false,
    download: false,
    rowsPerPage: 100,
    textLabels: {
        body: { noMatch: " لم يتم العثور على سجلات مطابقة", toolTip: "فرز", },
        pagination: { next: "الصفحة التالية", previous: "الصفحة السابقة", rowsPerPage: "عدد الصفوف", displayRows: "من", },
        toolbar: { search: "بحث", downloadCsv: "تنزيل", print: "Print", viewColumns: " التحكم بالاعمدة", filterTable: "فلتر", },
        filter: { all: "الكل", title: "فلتر", reset: "إعادة تعيين", },
        viewColumns: { title: "عرض الأعمدة", titleAria: "إظهار / إخفاء أعمدة الجدول", },
    }
}
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            unActiveitems: [],
            NameOfficeLink: '',
            office: [],
            wattingGetOffice: true,
            spin:true,
        }
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: { textAlign: 'right', },
            },
            MuiTableCell: {
                root: { textAlign: 'right', },
                // head: {  backgroundColor: "#FF0000", }
            },
        }
    })
    componentDidMount() {
        var NameOfficeLink = localStorage.getItem('NameOfficeLink')
        var header = { "Content-Type": "application/json", token: cookies.get("token") };


        axios.get(host + `dashbord/office/items/unactive/${this.props.match.params.id}`, { headers: header })
            .then(res => {
                let arr = [];
                // console.log(res.data.data.items);
                for (let i = 0; i < res.data.data.items.length; i++) {

                    let obj = {
                        image: <img src={host + 'files/' + res.data.data.items[i].photo} alt='img' style={{ width: 100, height: 80 }} />,
                        name: res.data.data.items[i].name,
                        type: res.data.data.items[i].type === 0 ? 'عدد' : 'وزن',
                        price: res.data.data.items[i].price,
                        count: res.data.data.items[i].count,
                        barcode: res.data.data.items[i].barcode,
                        desc: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        onCloseComplete={() => setState({ isShown: false })}
                                        hasFooter={false}
                                        hasHeader={false}
                                    >
                                        <div style={{ textAlign: 'right', width: '100%' }}>
                                            <p>{res.data.data.items[i].description}</p>
                                            <Barcode value={res.data.data.items[i].barcode} />
                                        </div>
                                    </Dialog>

                                    <div id='iconTableContiner' onClick={() => setState({ isShown: true })}>
                                        <VisibilityIcon style={{ color: '#0c4c91', cursor: 'pointer' }}
                                        /></div>
                                </Pane>
                            )}
                        </Component>,
                        edit: <Link to={`/EditItem/${this.props.match.params.id}`}>
                            <div id='iconTableContiner' onClick={() => {
                                localStorage.setItem('OfficeIdBack', this.props.match.params.id)
                                localStorage.setItem('idItemE', res.data.data.items[i].id)
                                localStorage.setItem('nameItemE', res.data.data.items[i].name)
                                localStorage.setItem('typeItemE', res.data.data.items[i].type)
                                localStorage.setItem('priceItemE', res.data.data.items[i].price)
                                localStorage.setItem('countItemE', res.data.data.items[i].count)
                                localStorage.setItem('barcodeItemE', res.data.data.items[i].barcode)
                                localStorage.setItem('descriptionItemE', res.data.data.items[i].description)
                                localStorage.setItem('returnto', 'UnActiveItem')
                            }}
                            ><EditIcon /></div>
                        </Link>,
                        active: <Component initialState={{ isShown: false, spin: true, office: [], office_ids: [], }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="تفعيل مادة لفرع"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="تفعيل"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            this.unactiveItem(res.data.data.items[i].id, state.office_ids, 0);

                                        }}
                                    >
                                        {state.spin ?
                                            <div id='wattingGetOffice'><Spinner /> </div> :
                                            <div id='DailogCheckContiner' >
                                                <Row style={{ margin: 0, width: '100%', direction: 'rtl', marginBottom: 10 }}>
                                                    <Col id='colOfficeCheckDailog' md={6} >
                                                        <div className="inputGroup">
                                                            <input style={{ width: 300 }} id={'option'} name={'option'} type="checkbox" onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    for (let i = 0; i < state.office.length; i++) {
                                                                        document.getElementById('option' + i).checked = true;
                                                                        state.office_ids.push(state.office[i].id)
                                                                    }
                                                                }
                                                                else {
                                                                    for (let i = 0; i < state.office.length; i++) {
                                                                        document.getElementById('option' + i).checked = false;
                                                                        setState({ office_ids: [] })
                                                                    }
                                                                }
                                                            }
                                                            } />
                                                            <label htmlFor={'option'}>تحديد الكل</label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ margin: 0, width: '100%', direction: 'rtl' }}>
                                                    {state.office.map((office, i) =>
                                                        <Col key={office.id} id='colOfficeCheckDailog' md={6} >
                                                            <div className="inputGroup">
                                                                <input id={'option' + i} name={'option' + i} value={office.id} type="checkbox" onChange={(e) => {
                                                                    // console.log(e.target.value)
                                                                    if (e.target.checked) {
                                                                        let newOffice_id = state.office_ids
                                                                        newOffice_id.push(Number(e.target.value))
                                                                        setState({ office_ids: newOffice_id })
                                                                    } else {
                                                                        let newOffice_id = state.office_ids.filter(id => Number(id) !== Number(e.target.value))
                                                                        setState({ office_ids: newOffice_id })
                                                                    }
                                                                }} />
                                                                <label htmlFor={'option' + i}>{office.name}</label>
                                                            </div>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </div>
                                        }
                                    </Dialog>
                                    <div id='iconTableContiner' onClick={() => {
                                        setState({ isShown: true, spin: true, office_ids: [] })
                                        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
                                        axios.get(host + `dashbord/office/`, { headers: header })
                                            .then(res => { setState({ office: res.data.data.offices, spin: false }); })
                                            .catch(error => { console.log(error.response) })

                                    }}>
                                        <ToggleOffIcon />
                                    </div>

                                </Pane>
                            )}
                        </Component>,
                        delete: <Component initialState={{ isShown: false, spin: true, office_ids: [] }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="حذف مادة من فرع "
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="حذف"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            this.deleteItem(res.data.data.items[i].id, state.office_ids)
                                        }}
                                    >
                                        {state.spin ?
                                            <div id='wattingGetOffice'><Spinner /> </div> :
                                            <div id='DailogCheckContiner' >
                                                <Row style={{ margin: 0, width: '100%', direction: 'rtl', marginBottom: 10 }}>
                                                    <Col id='colOfficeCheckDailog' md={6} >
                                                        <div className="inputGroup">
                                                            <input style={{ width: 300 }} id={'option'} name={'option'} type="checkbox" onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    for (let i = 0; i < state.office.length; i++) {
                                                                        document.getElementById('option' + i).checked = true;
                                                                        state.office_ids.push(state.office[i].id)
                                                                    }
                                                                }
                                                                else {
                                                                    for (let i = 0; i < state.office.length; i++) {
                                                                        document.getElementById('option' + i).checked = false;
                                                                        setState({ office_ids: [] })
                                                                    }
                                                                }
                                                            }
                                                            } />
                                                            <label htmlFor={'option'}>تحديد الكل</label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ margin: 0, width: '100%', direction: 'rtl' }}>
                                                    {state.office.map((office, i) =>
                                                        <Col key={office.id} id='colOfficeCheckDailog' md={6} >
                                                            <div className="inputGroup">
                                                                <input id={'option' + i} name={'option' + i} value={office.id} type="checkbox" onChange={(e) => {
                                                                    // console.log(e.target.value)
                                                                    if (e.target.checked) {
                                                                        let newOffice_id = state.office_ids
                                                                        newOffice_id.push(Number(e.target.value))
                                                                        setState({ office_ids: newOffice_id })
                                                                    } else {
                                                                        let newOffice_id = state.office_ids.filter(id => Number(id) !== Number(e.target.value))
                                                                        setState({ office_ids: newOffice_id })
                                                                    }
                                                                }} />
                                                                <label htmlFor={'option' + i}>{office.name}</label>
                                                            </div>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </div>
                                        }
                                    </Dialog>
                                    <div id='iconTableContiner' onClick={() => {
                                        setState({ isShown: true, spin: true, office_ids: [] })
                                        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
                                        axios.get(host + `dashbord/office/`, { headers: header })
                                            .then(res => { setState({ office: res.data.data.offices, spin: false }); })
                                            .catch(error => { console.log(error.response) })
                                    }}>
                                        <DeleteForeverIcon style={{ color: '#d87373de', cursor: 'pointer' }} />
                                    </div>
                                </Pane>
                            )}
                        </Component>,
                    };
                    arr.push(obj);
                }
                this.setState({
                    unActiveitems: arr,NameOfficeLink,spin:false
                });
            })
            .catch(error => { console.log(error.response) 
                this.setState({spin:false });
            })

    }

    unactiveItem(ItemId, office_ids, value) {
        axios.put(host + `items/office/disable/${ItemId}`,
            qs.stringify({
                status: value, office_id: office_ids
            }), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
            }
        }).then(response => {
            toast('تم تفعيل المادة', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            this.componentDidMount()
        }).catch(error => {
            console.log(error.response.data.message)
            toast(error.response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        })
    }
    deleteItem(ItemId, office_ids) {
        axios.post(host + `items/delete/${ItemId}`,
            qs.stringify({ office_id: office_ids }), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
            }
        }).then(response => {
            toast('تم حذف المادة', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            this.componentDidMount()
        }).catch(error => {
            console.log(error.response.data.message)
            toast(error.response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        })
    }
    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    if (cookies.get("token")) {
                        if (!this.state.spin) {
                        return (
                            <div>
                                <div id='navAddItem'>
                                    <Link to={`/OfficeOption/${this.props.match.params.id}`}>
                                        <KeyboardBackspaceIcon style={{ cursor: 'pointer', height: 35, width: 35, color: '#fff' }} />
                                    </Link>
                                    <div>فرع  {this.state.NameOfficeLink} / المواد المحجوبة</div>
                                </div>
                                <div className='DataTableContiner'>
                                    <MuiThemeProvider
                                        theme={this.getMuiTheme()}>
                                        <MaterialDatatable data={this.state.unActiveitems} columns={columns1} options={options} />
                                    </MuiThemeProvider>
                                </div>

                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />

                            </div>
                        )
                        }
                        else {
                            return (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }}>
                                    <Spinner />
                                </div>
                            )
                        }
                    }


                    else {
                        return (
                            <Login />
                        )
                    }
                }
                }

            </Context.Consumer>
        )
    }
}

export default Home;