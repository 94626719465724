import React from 'react';
import Context from '../../../assets/js/Context';
import { Map , TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import qs from 'qs';
import axios from 'axios';
import { Spinner } from 'evergreen-ui';
import host from '../../../assets/js/Host';
import Login from '../../common/login';
import Cookies from "universal-cookie";
import 'react-toastify/dist/ReactToastify.css';

const cookies = new Cookies();
var myIcon = new L.Icon({
    iconUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAFgUlEQVR4Aa1XA5BjWRTN2oW17d3YaZtr2962HUzbDNpjszW24mRt28p47v7zq/bXZtrp/lWnXr337j3nPCe85NcypgSFdugCpW5YoDAMRaIMqRi6aKq5E3YqDQO3qAwjVWrD8Ncq/RBpykd8oZUb/kaJutow8r1aP9II0WmLKLIsJyv1w/kqw9Ch2MYdB++12Onxee/QMwvf4/Dk/Lfp/i4nxTXtOoQ4pW5Aj7wpici1A9erdAN2OH64x8OSP9j3Ft3b7aWkTg/Fm91siTra0f9on5sQr9INejH6CUUUpavjFNq1B+Oadhxmnfa8RfEmN8VNAsQhPqF55xHkMzz3jSmChWU6f7/XZKNH+9+hBLOHYozuKQPxyMPUKkrX/K0uWnfFaJGS1QPRtZsOPtr3NsW0uyh6NNCOkU3Yz+bXbT3I8G3xE5EXLXtCXbbqwCO9zPQYPRTZ5vIDXD7U+w7rFDEoUUf7ibHIR4y6bLVPXrz8JVZEql13trxwue/uDivd3fkWRbS6/IA2bID4uk0UpF1N8qLlbBlXs4Ee7HLTfV1j54APvODnSfOWBqtKVvjgLKzF5YdEk5ewRkGlK0i33Eofffc7HT56jD7/6U+qH3Cx7SBLNntH5YIPvODnyfIXZYRVDPqgHtLs5ABHD3YzLuespb7t79FY34DjMwrVrcTuwlT55YMPvOBnRrJ4VXTdNnYug5ucHLBjEpt30701A3Ts+HEa73u6dT3FNWwflY86eMHPk+Yu+i6pzUpRrW7SNDg5JHR4KapmM5Wv2E8Tfcb1HoqqHMHU+uWDD7zg54mz5/2BSnizi9T1Dg4QQXLToGNCkb6tb1NU+QAlGr1++eADrzhn/u8Q2YZhQVlZ5+CAOtqfbhmaUCS1ezNFVm2imDbPmPng5wmz+gwh+oHDce0eUtQ6OGDIyR0uUhUsoO3vfDmmgOezH0mZN59x7MBi++WDL1g/eEiU3avlidO671bkLfwbw5XV2P8Pzo0ydy4t2/0eu33xYSOMOD8hTf4CrBtGMSoXfPLchX+J0ruSePw3LZeK0juPJbYzrhkH0io7B3k164hiGvawhOKMLkrQLyVpZg8rHFW7E2uHOL888IBPlNZ1FPzstSJM694fWr6RwpvcJK60+0HCILTBzZLFNdtAzJaohze60T8qBzyh5ZuOg5e7uwQppofEmf2++DYvmySqGBuKaicF1blQjhuHdvCIMvp8whTTfZzI7RldpwtSzL+F1+wkdZ2TBOW2gIF88PBTzD/gpeREAMEbxnJcaJHNHrpzji0gQCS6hdkEeYt9DF/2qPcEC8RM28Hwmr3sdNyht00byAut2k3gufWNtgtOEOFGUwcXWNDbdNbpgBGxEvKkOQsxivJx33iow0Vw5S6SVTrpVq11ysA2Rp7gTfPfktc6zhtXBBC+adRLshf6sG2RfHPZ5EAc4sVZ83yCN00Fk/4kggu40ZTvIEm5g24qtU4KjBrx/BTTH8ifVASAG7gKrnWxJDcU7x8X6Ecczhm3o6YicvsLXWfh3Ch1W0k8x0nXF+0fFxgt4phz8QvypiwCCFKMqXCnqXExjq10beH+UUA7+nG6mdG/Pu0f3LgFcGrl2s0kNNjpmoJ9o4B29CMO8dMT4Q5ox8uitF6fqsrJOr8qnwNbRzv6hSnG5wP+64C7h9lp30hKNtKdWjtdkbuPA19nJ7Tz3zR/ibgARbhb4AlhavcBebmTHcFl2fvYEnW0ox9xMxKBS8btJ+KiEbq9zA4RthQXDhPa0T9TEe69gWupwc6uBUphquXgf+/FrIjweHQS4/pduMe5ERUMHUd9xv8ZR98CxkS4F2n3EUrUZ10EYNw7BWm9x1GiPssi3GgiGRDKWRYZfXlON+dfNbM+GgIwYdwAAAAASUVORK5CYII',
    iconSize: [25, 41],
    iconAnchor: [12.5, 41],
    popupAnchor: [0, -41],

})

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            draggable: true,
            haveLocation: false,
            zoom: 13,
            location: {
                lat: '',
                lng: '',
            },
            nameOffice: '',
            passwordOffice: '',
            phoneOffice:'',
            spinnerBtn: false,
            Modal: false,
        }
    }
    componentDidMount() {
        navigator.geolocation.getCurrentPosition((Position) => {
            this.setState({ location: { lat: Position.coords.latitude, lng: Position.coords.longitude }, haveLocation: true })

            // console.log(Position.coords.latitude, Position.coords.longitude);
        });
    }

    updatePosition(e) {
        this.setState({ location: { lat: e.target._latlng.lat, lng: e.target._latlng.lng }, zoom: e.target.zoom })
    }
    AddOffice() { 
        axios.post(host + `dashbord/addOffice/`,
            qs.stringify({
                name: this.state.nameOffice, password: this.state.passwordOffice, phone: this.state.phoneOffice,
                latitude: this.state.location.lat, longitude: this.state.location.lng   
            }) , { headers: { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") }
            }).then(response => {
           window.location.href='/Office'
        }).catch(error => {
            this.setState({ spinnerBtn: false })
            console.log(error.response.data)
        })
    }

    render() {
        return (
            <Context.Consumer>
                {ctx => {
                       if (cookies.get("token")) {
                    if (this.state.haveLocation) {
                    return (

                        <div>
                            <Map  className='map' center={[this.state.location.lat, this.state.location.lng]} zoom={this.state.zoom}>
                                <TileLayer
                                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker
                                    position={[this.state.location.lat, this.state.location.lng]}
                                    draggable={this.state.draggable}
                                    onDragend={(e) => this.updatePosition(e)}
                                    icon={myIcon}>
                                    <Popup>
                                        <div id='popupContent'>
                                            <input autoComplete='off' type='text' placeholder='اسم الفرع' id='InputTExtDash260'
                                                onChange={(e) => this.setState({ nameOffice: e.target.value })} />

                                            <input autoComplete='off' type='password' placeholder=' كلمه المرور ' id='InputTExtDash260'
                                                onChange={(e) => this.setState({ passwordOffice: e.target.value })} />

                                            <input autoComplete='off' type='text' placeholder=' رقم الهاتف' id='InputTExtDash260'
                                                onChange={(e) => this.setState({ phoneOffice: e.target.value })} />

                                            <div id='btnAddItemContiner'>
                                                {!this.state.spinnerBtn ? (
                                                    <div id='btnAddItem' onClick={() => {
                                                        this.setState({ spinnerBtn: true })
                                                        this.AddOffice()
                                                    }}>اضافه</div>
                                                ) : (
                                                        <div id='btnAddItem' ><Spinner size={24} /></div>)}
                                            </div>
                                        </div>
                                    </Popup>
                                </Marker>
                            </Map >
                        </div>
                    )
                    }
                    else {
                        return (
                            <div style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Spinner />
                            </div>
                        )
                    }
                }
                else {
                    return (
                        <Login />
                    )
                }
                }
                }

            </Context.Consumer>
        )
    }
}
export default Dashboard;