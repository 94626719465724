import React from 'react';
import Context from '../../assets/js/Context';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Component from "@reactions/component";
import "react-datepicker/dist/react-datepicker.css";
import qs from 'qs';
import { Dialog, Pane, SideSheet, Spinner, Position } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from "universal-cookie";
import * as moment from "moment-timezone";
import DatePicker from "react-datepicker";
// import * as moment from "moment";
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import host from '../../assets/js/Host';
import axios from 'axios';
const cookies = new Cookies();
const columns = [
    { field: "delete", name: "حذف", options: { width: 80, filter: true, sort: true, } },
    { field: "date", name: "تاريخ الانتهاء", options: { filter: true, sort: false, } },
    { field: "count", name: "عدد المستخدمين", options: { filter: true, sort: false, } },
    { field: "precent", name: "النسبة", options: { filter: true, sort: true, } },
    { field: "code", name: "الكود", options: { filter: true, sort: true, } },
    { field: "id", name: "#", options: { width: 80, filter: true, sort: true, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
}
class Device extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subs: [],
            percent: '',
            count: '',
            from: new Date(),
            expire: false,
            spinPage: true,
        }
    }
    // var date1 = moment(document.getElementById('date1').value).format('DD/MM/YYYY');
    componentDidMount() {
        // var officeId = localStorage.getItem('officeId')
        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.get(host + `dashbord/promocode/all`, { headers: header })
            .then(res => {
                let arr = [];
                // console.log(res.data.data.promocode);
                for (let i = 0; i < res.data.data.promocode.length; i++) {

                    let obj = {
                        id: res.data.data.promocode[i].id,
                        code: res.data.data.promocode[i].code,
                        precent: res.data.data.promocode[i].precent,
                        count: res.data.data.promocode[i].count,
                        date: moment(res.data.data.promocode[i].expiry_date).tz('Asia/baghdad').format('YYYY/MM/DD LT'),
                        delete: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="حذف بروموكود "
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="تاكيد"
                                        cancelLabel='الغاء'
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            this.deleteSubscriptions(res.data.data.promocode[i].id)
                                        }}
                                    >
                                        <span id='msgDelete'>هل انت متاكد من عملية الحذف</span>
                                    </Dialog>
                                    <DeleteForeverIcon style={{ color: '#d87373de', cursor: 'pointer' }}
                                        onClick={() => setState({ isShown: true })} />
                                </Pane>
                            )}
                        </Component>,
                    };
                    arr.push(obj);
                }
                this.setState({
                    items: arr, spinPage: false
                });
            })
            .catch(error => {
                if (error.response.data.statusCode === 401) {
                    this.setState({ expire: true })
                }
                console.log(error.response.data)
            })
    }
    handleChangeFrom = date => {
        this.setState({
            from: date
        });
    };
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'center',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'center',

                },
                head: {
                    // backgroundColor: "#FF0000",
                    textTransform: 'capitalize',
                }
            },

        }
    })
    deleteSubscriptions(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
        axios.delete(host + `dashbord/promocode/${id}`, { headers: header })
            .then(response => {
                toast('تم الحذف', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }


    AddSubscription() {
        axios.post(host + `dashbord/promocode/new`,
            qs.stringify({ precent: this.state.percent, expiry_date: this.state.from, count: this.state.count }), {
            headers: { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") }
        }).then(response => {
            this.setState({ nameCate: '', BtnAdd: false })
            toast('تمت الاضافه بنجاح', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            this.componentDidMount()
        }).catch(error => {
            console.log(error.response.data.message)
            toast(error.response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        })
    }
    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    if (!this.state.spinPage) {
                        return (
                            <div >
                                <div id='btnHeaderContiner'>
                                    <Component initialState={{ isShown: false }}>
                                        {({ state, setState }) => (
                                            <React.Fragment>
                                                <SideSheet
                                                    isShown={state.isShown}
                                                    position={Position.LEFT}
                                                    onCloseComplete={() => setState({ isShown: false })}
                                                >
                                                    <div margin={40}>
                                                        <div id='titleAddUser' style={{ backgroundColor: '#c1a269'}}>اضافة بروموكود</div>
                                                        <div id='inputAdd2Continer' >
                                                            <div id={'ContinerInPut'} >
                                                                <label>النسبه </label>
                                                                <input autoComplete='off' type='text' placeholder='النسبه' id='InputTExtDash111'
                                                                    onChange={(e) => this.setState({ percent: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'} >
                                                                <label>عدد المستخدمين</label>
                                                                <input autoComplete='off' type='text' placeholder='عدد المستخدمين' id='InputTExtDash111'
                                                                    onChange={(e) => this.setState({ count: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'} >
                                                                <label>تاريح الانتهاء</label>
                                                                <DatePicker id='InputTExtDash111'
                                                                    dateFormat="yyyy/MM/dd"
                                                                    selected={this.state.from}
                                                                    onChange={this.handleChangeFrom}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div id='btnAddContiner'>
                                                            <div id='BtnAddUser' style={{ backgroundColor: '#c1a269' }} onClick={() => {
                                                                this.AddSubscription()
                                                                setState({ isShown: false })
                                                            }}>اضافة</div>
                                                        </div>

                                                    </div>
                                                </SideSheet>
                                                <div id='BtnAddUser' style={{ width: 150, backgroundColor: '#c1a269',margin:20  }} onClick={() => setState({ isShown: true })}>اضافة بروموكود</div>
                                            </React.Fragment>
                                        )}
                                    </Component>

                                </div>
                                <div>
                                    {!this.state.spinPage ?
                                        <div className='DataTableContiner'>
                                            <MuiThemeProvider
                                                theme={this.getMuiTheme()}>
                                                <MaterialDatatable data={this.state.items} columns={columns} options={options} />
                                            </MuiThemeProvider>
                                        </div>
                                        :
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh', width: '100%' }}>
                                            <Spinner />
                                        </div>
                                    }
                                </div>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                    }
                    else {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }}>
                                <Spinner />
                            </div>
                        )
                    }
                }
                }

            </Context.Consumer>
        )
    }
}

export default Device;