
import React from 'react';
import Context from '../../../assets/js/Context';
import { Row, Col } from 'react-bootstrap';
import Cookies from "universal-cookie";
import Component from "@reactions/component";
import { SideSheet, Position, Spinner } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import host from '../../../assets/js/Host';
import axios from 'axios';
import qs from 'qs';
const cookies = new Cookies();
class Setting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            minOrder: '',

        }
    }
    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };

        axios.get(host + `dashbord/MinOrder/`, { headers: header })
            .then(res => {
                console.log(res.data.data.minOrder);
                this.setState({
                    minOrder: res.data.data.minOrder.price,
                });
            })
            .catch(error => { console.log(error) })
    }
    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    // if (ctx.value.profile.role === 4) {
                    return (
                        <div style={{ width: '100%', height: '100vh', overflowY: 'scroll' }}>
                            <div id='contentSeettingContiner'>
                                <Row style={{ margin: 0, width: '100%' }}>

                                    <Col id='colSetting' sm={12} md={6} lg={4} xl={3} >
                                        <Component initialState={{ isShown: false, spinBtn: false, nameD: '', phoneD: '', govD: '', typeD: '', }}>
                                            {({ state, setState }) => (
                                                <React.Fragment>
                                                    <SideSheet
                                                        isShown={state.isShown}
                                                        position={Position.LEFT}
                                                        onCloseComplete={() => setState({ isShown: false })}
                                                    >
                                                        <div margin={40}>
                                                            <div id='titleAddUser' style={{ backgroundColor: '#3A3A3A' }} >اقل سعر للطلب</div>
                                                            <div id='inputAdd2Continer' >

                                                                <div id={'ContinerInPut'} >
                                                                    <label> السعر </label>
                                                                    <input autoFocus autoComplete='off' type='number' placeholder='السعر' id='InputTExtDash111'
                                                                        value={this.state.minOrder} onChange={(e) => this.setState({ minOrder: e.target.value })} />
                                                                </div>

                                                            </div>
                                                            <div id='btnAddContiner'>
                                                                {!state.spinBtn ?
                                                                    <div id='BtnAddUser' style={{ backgroundColor: '#3A3A3A' }} onClick={() => {
                                                                        setState({ spinBtn: true })
                                                                        axios.post(host + `dashbord/MinOrder/`,
                                                                            qs.stringify({ price: this.state.minOrder }), {
                                                                            headers: { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") }
                                                                        }).then(response => {
                                                                            toast('تم التعديل بنجاح', {
                                                                                position: "bottom-center",
                                                                                autoClose: 5000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: true
                                                                            });
                                                                            setState({isShown:false})
                                                                        }).catch(error => {
                                                                            setState({ isShown: false, spinBtn: false })
                                                                            console.log(error)
                                                                        })


                                                                    }}>تعديل</div>
                                                                    :
                                                                    <div id='BtnAddUser' style={{ backgroundColor: '#3A3A3A' }} ><Spinner size={20} /></div>
                                                                }
                                                            </div>

                                                        </div>
                                                    </SideSheet>
                                                    <div id='SEEEEtPr'
                                                        onClick={() => setState({ isShown: true })}>
                                                        <h6>اقل سعر للطلب</h6>
                                                        <h6> {this.state.minOrder}</h6>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </Component>
                                    </Col>


                                </Row>

                            </div>


                            <ToastContainer
                                position="bottom-left"
                                autoClose={10000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnVisibilityChange
                                draggable
                                pauseOnHover
                            />
                        </div>
                    )
                    // }
                    // else {
                    //     return (
                    //         <Login />
                    //     )
                    // }
                }
                }

            </Context.Consumer >
        )
    }
}

export default Setting;