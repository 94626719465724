import React from 'react';
import Context from '../../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../../assets/js/Host';
import axios from 'axios';

import { Link } from 'react-router-dom';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { Spinner,  } from 'evergreen-ui';

import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';


const columns = [

    { field: "enter", name: "دخول", options: { width: 60, filter: true, sort: false, } },

    { field: "name", name: "الاسم", options: { filter: true, sort: true, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
    viewColumns:false,
    rowsPerPage: 20,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();

class Items extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recipes: [],
            spin: false,
            nameItem: '',
            descItem: '',
            image: [],
        }
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })
    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/governorate/get/all`, { headers: header })
            .then(res => {
                let arr = [];
                console.log(res.data.data.governorate);
                for (let i = 0; i < res.data.data.governorate.length; i++) {

                    let obj = {
                        name: res.data.data.governorate[i].name,

                        enter: <Link to={`/CityDash/${res.data.data.governorate[i].id}`} onClick={() => {
                            localStorage.setItem('NameOfficeLink', res.data.data.governorate[i].name)
                        }} >
                            <div id='iconTableContiner' >
                                <OpenInBrowserIcon style={{ color: 'green' }} />
                            </div>
                        </Link>,

                    };
                    arr.push(obj);
                }
                this.setState({
                    recipes: arr, spin: true
                });
            })
            .catch(error => { console.log(error) })

    }


    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    if (this.state.spin) {
                        return (
                            <div >
                                {/* <TimePic/> */}
                              
                                <div className='DataTableContiner'>
                                    <MuiThemeProvider
                                        theme={this.getMuiTheme()}>
                                        <MaterialDatatable data={this.state.recipes} columns={columns} options={options} />
                                    </MuiThemeProvider>
                                </div>
                             
                            </div>

                        )
                    }
                    else {
                        return (
                            <div style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Spinner />
                            </div>
                        )
                    }

                }
                }
            </Context.Consumer>
        )
    }
}


export default Items;