import React from 'react';
import Context from '../../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../../assets/js/Host';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'evergreen-ui'
import { Col, Row } from 'react-bootstrap';
import qs from 'qs';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
// import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment-timezone";
import Login from '../../common/login';

const columns1 = [
    { field: "gift", name: "هدية", options: {width: 50, filter: true, sort: true, } },
    { field: "desc", name: "التفاصيل ", options: { filter: true, sort: false, } },
    { field: "count", name: "العدد ", options: { filter: true, sort: false, } },
    { field: "name", name: "الاسم", options: { filter: true, sort: true, } },
    { field: "image", name: "صوره", options: { width: 120, filter: true, sort: false, } },
];

const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    filter: false,
    print: false,
    download: false,
    search:false,
    viewColumns: false,
    rowsPerPage: 100,
    textLabels: {
        body: { noMatch: " لم يتم العثور على سجلات مطابقة", toolTip: "فرز", },
        pagination: { next: "الصفحة التالية", previous: "الصفحة السابقة", rowsPerPage: "عدد الصفوف", displayRows: "من", },
        toolbar: { search: "بحث", downloadCsv: "تنزيل", print: "Print", viewColumns: " التحكم بالاعمدة", filterTable: "فلتر", },
        filter: { all: "الكل", title: "فلتر", reset: "إعادة تعيين", },
        viewColumns: { title: "عرض الأعمدة", titleAria: "إظهار / إخفاء أعمدة الجدول", },
    }
}
const cookies = new Cookies();

class Items extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            spin: false,
            order: [],
            info: [],
            data: [],
            phoneOrder: '',
            nameOrder: '',
            statusOrder: '',
            driverOption: [],
            idDriverSelect: '',
            sendTOdriver: false,
            sendTOonProcess: false,
            idOrder: '',
            DateRecive: '',
            DateRecive1: '',
            spinBtn1: false,
            spinBtn2: false,
            latitude: '',
            longitude: '',
            totalprice: '',
            spinPage: true,
            is_gift: 0,
        }
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'center',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'center',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })
    componentDidMount() {
        var totalprice = localStorage.getItem('totalpriceorder')

        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
        axios.get(host + `orders/order/${this.props.match.params.id}`, { headers: header })
            .then(res => {
                let arr = [];
                console.log(res.data.data.order);
                for (let i = 0; i < res.data.data.order.orderItems.length; i++) {

                    let obj = {
                        image: <img src={host + 'files/' + res.data.data.order.orderItems[i].cove_photo} alt='img' style={{ width: 120, height: 120 }} />,
                        name: res.data.data.order.orderItems[i].name,
                        count: res.data.data.order.orderItems[i].count,
                        desc: res.data.data.order.orderItems[i].description,
                        gift: res.data.data.order.orderItems[i].is_gift ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />,
                    }
                    arr.push(obj);
                }
                this.setState({
                    order: arr, spinerMod: false, info: res.data.data.order, spinPage: false,
                    longitude: res.data.data.order.longitude, latitude: res.data.data.order.latitude, totalprice
                })

            })
            .catch(error => { console.log(error) })
    }
    cahngeStatus(value) {
        axios.put(host + `orders/orders/${this.props.match.params.id}`,
            qs.stringify({ status: value }), {
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") }
        }).then(response => {
            toast('تم تغيير حالة الطلب بنجاح', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            this.setState({ spinBtn1: false, spinBtn2: false })
            localStorage.setItem('approveOrder', 'false')
            this.componentDidMount()
        }).catch(error => {
            this.setState({ spinBtn1: false, spinBtn2: false })
            console.log(error)
        })
    }

    render() {

        return (
            <Context.Consumer>
                {ctx => {
                    if (cookies.get("token")) {
                        if (!this.state.spinPage) {
                            return (
                                <div >
                                    <Row style={{ direction: 'rtl', padding: 0, margin: 10, marginTop: 20 }}>
                                        <Col sm={6} md={3} lg={2} id='colOrderBy'>
                                            <div id='textDescPagelabel'>رقم الطلب </div>
                                            <div id='textDescPagecontent'>{this.state.info.id}</div>
                                        </Col>
                                        <Col sm={6} md={3} lg={2} id='colOrderBy'>
                                            <div id='textDescPagelabel'>الاسم </div>
                                            <div id='textDescPagecontent'>{this.state.info.name}</div>
                                        </Col>
                                        <Col sm={6} md={3} lg={2} id='colOrderBy'>
                                            <div id='textDescPagelabel'>رقم الهاتف </div>
                                            <div id='textDescPagecontent'>{this.state.info.phone}</div>
                                        </Col>
                                        <Col sm={6} md={3} lg={2} id='colOrderBy'>
                                            <div id='textDescPagelabel'>العنوان </div>
                                            <div id='textDescPagecontent'>{this.state.info.city + ' / ' + this.state.info.address}</div>
                                        </Col>
                                        <Col sm={6} md={3} lg={2} id='colOrderBy'>
                                            <div id='textDescPagelabel'>المبلغ الكلي </div>
                                            <div id='textDescPagecontent'>{this.state.info.price}</div>
                                        </Col>
                                        <Col sm={6} md={3} lg={2} id='colOrderBy'>

                                            <div id='textDescPagelabel'>التاريخ </div>
                                            <div id='textDescPagecontent'>{moment(this.state.info.createdAt).tz('Asia/baghdad').format('YYYY/MM/DD LT')}</div>
                                        </Col>
                                    </Row>
                                    <h4 className='OrderSByILabel'>ملاحظات</h4>
                                    <div className='OrderSByINote'>
                                        {this.state.info.notes}
                                    </div>
                                    <div className='DataTableContiner1'>
                                        <MuiThemeProvider
                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable data={this.state.order} columns={columns1} options={options} />
                                        </MuiThemeProvider>
                                    </div>
                                    <ToastContainer
                                        position="bottom-left"
                                        autoClose={10000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                            )
                        } else {
                            return (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }}>
                                    <Spinner />
                                </div>
                            )
                        }
                    }
                    else {
                        return (
                            <Login />
                        )
                    }
                }
                }
            </Context.Consumer>
        )
    }
}


export default Items;