
import React from 'react';
import Context from '../../assets/js/Context';
import { Row, Col, Table, Form } from 'react-bootstrap';
import Cookies from "universal-cookie";
import { Spinner } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import host from '../../assets/js/Host';
import axios from 'axios';
const cookies = new Cookies();

class Setting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            name: '',
            price: '',
            offerPrice: '',
            count: '',
            desc: '',
            file: [],
            spin: false,
            spinPage: true,
            is_gift: 0,
        }
    }

    componentDidMount() {

        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.get(host + `items/item/${this.props.match.params.id}`, { headers: header })
            .then(res => {
                console.log(res.data.data.item);

                this.setState({
                    data: res.data.data.item,
                    name: res.data.data.item.name,
                    price: res.data.data.item.price,
                    offerPrice: res.data.data.item.offer_price,
                    count: res.data.data.item.count,
                    desc: res.data.data.item.description,
                    is_gift: res.data.data.item.is_gift,
                    spinPage: false,
                });
            })
            .catch(error => { console.log(error) })
        //   window.addEventListener('scroll',5535.5556640625)
        //  window.pageYOffset(5535)

    }
    addItem() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        var formData = new FormData();
        formData.append("name", this.state.name);
        formData.append("price", this.state.price);
        formData.append("count", this.state.count);
        formData.append("is_gift", this.state.is_gift);
        formData.append("offer_price", this.state.offerPrice);
        formData.append("description", this.state.desc);
        if (this.state.file[0]) {
            formData.append("file", this.state.file[0]);
        }
        axios({ url: host + `items/edit/${this.props.match.params.id}`, method: "PUT", data: formData, headers: header })
            .then(response => {
                console.log(response);
                this.setState({ spinBtn: false })
                toast('تمت التعديل بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                // window.location.reload()

            })
            .catch(error => {
                this.setState({ spinBtn: false })
                toast(error.response.data.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                console.log(error)
            })
    }

    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    // if (ctx.value.profile.role === 4) {
                    return (
                        <div style={{ width: '100%', height: '100vh', overflowY: 'scroll' }}>
                            <div id='contentSeettingContiner'>
                                <Row style={{ margin: 0, width: '100%' }}>
                                    <Col id='colSetting' sm={12} md={6} lg={4} xl={3} >
                                    </Col>
                                </Row>
                                <Table striped bordered hover>
                                    <tbody>

                                        <tr style={{ textAlign: 'center', border: '2px soild #000' }}>
                                            <td>
                                                <div id='InputAddOrderContiner'>
                                                    <div id='InputAddOrderLabel'>الاسم</div>
                                                    <input autoFocus type='text' id='InputAddOrder' placeholder='الاسم'
                                                        value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                                                </div>
                                            </td>

                                            <td>
                                                <div id='InputAddOrderContiner'>
                                                    <div id='InputAddOrderLabel'>السعر</div>
                                                    <input type='number' id='InputAddOrder' placeholder='السعر'
                                                        value={this.state.price} onChange={(e) => this.setState({ price: e.target.value })} />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr style={{ textAlign: 'center', border: '2px soild #000' }}>
                                            <td>
                                                <div id='InputAddOrderContiner'>
                                                    <div id='InputAddOrderLabel'>سعر العرض</div>
                                                    <input type='number' id='InputAddOrder' placeholder='سعر العرض'
                                                        value={this.state.offerPrice} onChange={(e) => this.setState({ offerPrice: e.target.value })} />
                                                </div>
                                            </td>

                                            <td>
                                                <div id='InputAddOrderContiner'>
                                                    <div id='InputAddOrderLabel'>العدد</div>
                                                    <input type='number' id='InputAddOrder' placeholder='العدد'
                                                        value={this.state.count} onChange={(e) => this.setState({ count: e.target.value })} />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr style={{ textAlign: 'center', border: '2px soild #000' }}>

                                            <td>
                                                <div id='InputAddOrderContiner'>
                                                    <div id='InputAddOrderLabel'>الصوره</div>
                                                    <input type='file'
                                                        id="filePicker5"
                                                        onChange={file => {
                                                            console.log(file.target.files);
                                                            this.setState({ file: file.target.files, addTrue: true })
                                                        }}
                                                        placeholder="Select the file "
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div id='InputAddOrderContiner'>
                                                    <div id='InputAddOrderLabel'>التفاصيل</div>
                                                    <input type='text' id='InputAddOrder' placeholder='التفاصيل'
                                                        value={this.state.desc} onChange={(e) => this.setState({ desc: e.target.value })} />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr style={{ textAlign: 'center', border: '2px soild #000' }}>

                                            <td>
                                                <div id='InputAddOrderContiner'>
                                                    <div id='InputAddOrderLabel'>هديه ؟</div>
                                                    <Form.Control as="select" id='InputAddOrder' style={{ height: 38 }}
                                                        onChange={(even) => {
                                                            if (even.target.value !== 'Select') {
                                                                this.setState({ is_gift: Number(even.target.value) })
                                                            }
                                                        }}>
                                                        <option value='Select'>اختيار</option>
                                                        <option value={1}>نعم</option>
                                                        <option value={0} >كلا</option>
                                                    </Form.Control>
                                                </div>
                                            </td>
                                            <td>
                                                <div id='InputAddOrderContiner'>
                                                    {!this.state.spin ?
                                                        <div id='AddOrderBTn' onClick={() => { this.addItem() }}>تعديل</div>
                                                        : <div id='AddOrderBTn'><Spinner /></div>
                                                    }
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>


                                </Table>


                            </div>
                            <ToastContainer
                                position="bottom-left"
                                autoClose={10000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnVisibilityChange
                                draggable
                                pauseOnHover
                            />
                        </div>
                    )
                    // }
                    // else {
                    //     return (
                    //         <Login />
                    //     )
                    // }
                }
                }

            </Context.Consumer >
        )
    }
}

export default Setting;