import React from 'react';
import Context from '../../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../../assets/js/Host';
import axios from 'axios';
import EditIcon from '@material-ui/icons/Edit';
import { SideSheet, Position, Spinner } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import qs from 'qs';
import { Form } from 'react-bootstrap';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Component from "@reactions/component";
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
// import TimePic from '../../common/timePicker';

const columns = [
    { field: "edit", name: "تعديل", options: { width: 60, filter: true, sort: false, } },
    { field: "pFast", name: "سعر التوصيل السريع", options: { filter: true, sort: true, } },
    { field: "CFast", name: "توصيل سريع", options: { filter: true, sort: true, } },
    { field: "price", name: "سعر التوصيل", options: { filter: true, sort: true, } },
    { field: "name", name: "اسم المدينه", options: { filter: true, sort: true, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
    viewColumns: false,
    rowsPerPage: 100,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();

class Items extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recipes: [],
            spin: false,
            nameItem: '',
            descItem: '',
            image: [],
        }
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })
    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/governorate/city/${this.props.match.params.id}`, { headers: header })
            .then(res => {
                let arr = [];
                console.log(res.data.data.cities);
                for (let i = 0; i < res.data.data.cities.length; i++) {

                    let obj = {
                        name: res.data.data.cities[i].name,
                        price: res.data.data.cities[i].shipping_price,
                        CFast: res.data.data.cities[i].fast_shipping ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />,
                        pFast: res.data.data.cities[i].fast_price,
                        edit: <Component initialState={{ isShown: false, name: '', speice: '', fprice: '', type: 0, }}>
                            {({ state, setState }) => (
                                <React.Fragment>
                                    <SideSheet
                                        isShown={state.isShown}
                                        position={Position.LEFT}
                                        onCloseComplete={() => setState({ isShown: false })}
                                    >
                                        <div margin={40}>
                                            <div id='titleAddUser' style={{ backgroundColor: '#0c4c91' }}>({res.data.data.cities[i].name}) تعديل</div>
                                            <div id='inputAdd2Continer' >

                                                <div id={'ContinerInPut'} >
                                                    <label> اسم المدينه </label>
                                                    <input autoComplete='off' type='text' id='InputTExtDash111' style={{ height: 32, width: 250 }}
                                                        value={state.name} onChange={(e) => setState({ name: e.target.value })} />
                                                </div>
                                                <div id={'ContinerInPut'} >
                                                    <label> سعر التوصيل </label>
                                                    <input autoComplete='off' type='number' id='InputTExtDash111' style={{ height: 32, width: 250 }}
                                                        value={state.speice} onChange={(e) => setState({ speice: e.target.value })} />
                                                </div>
                                                <div id={'ContinerInPut'}>
                                                    <label>توصيل سريع</label>
                                                    <Form.Control as="select" id='InputTExtDash1' style={{ height: 38 }}
                                                        onChange={(even) => {
                                                            if (even.target.value !== 'Select') {
                                                                setState({ type: Number(even.target.value) })
                                                            }
                                                        }}>
                                                        <option value='Select'>اختيار</option>
                                                        <option value={1}>نعم</option>
                                                        <option value={0} >كلا</option>
                                                    </Form.Control>
                                                </div>
                                                {state.type === 1 ?
                                                    <React.Fragment>

                                                        <div id={'ContinerInPut'} >
                                                            <label> سعر التوصيل السريع </label>
                                                            <input autoComplete='off' type='number' id='InputTExtDash111' style={{ height: 32, width: 250 }}
                                                                value={state.fprice} onChange={(e) => setState({ fprice: e.target.value })} />
                                                        </div>
                                                    </React.Fragment>
                                                    : null}
                                            </div>
                                            <div id='btnAddContiner'>
                                                <div id='BtnAddUser' style={{ backgroundColor: '#0c4c91' }} onClick={() => {
                                                    axios.post(host + `dashbord/city/edit/${res.data.data.cities[i].id}`,
                                                        qs.stringify({
                                                            name: state.name,
                                                            shipping_price: state.speice,
                                                            fast_shipping: state.type,
                                                            fast_price: state.type === 0 ? 0 : state.fprice,
                                                            
                                                        }), {
                                                        headers: { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") }
                                                    }).then(response => {
                                                        setState({ isShown: false })
                                                        this.componentDidMount()
                                                        toast("تم التعديل بنجاح", {
                                                            position: "bottom-center",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true
                                                        });
                                                    }).catch(error => {
                                                        this.setState({ spinnerBtn: false })
                                                        console.log(error.response.data)
                                                    })

                                                }}>تعديل</div>
                                            </div>

                                        </div>
                                    </SideSheet>
                                    <div id='iconTableContiner' onClick={() => setState({
                                        isShown: true,
                                        name: res.data.data.cities[i].name,
                                        speice: res.data.data.cities[i].shipping_price,
                                        type: res.data.data.cities[i].fast_shipping ? 1 : 0,
                                        fprice: res.data.data.cities[i].fast_price,
                                    })} >
                                        <EditIcon style={{ color: 'blue' }} />
                                    </div>
                                </React.Fragment>
                            )}
                        </Component>,


                    };
                    arr.push(obj);
                }
                this.setState({
                    recipes: arr, spin: true
                });
            })
            .catch(error => { console.log(error) })

    }
    enable(id, value) {
        axios.put(host + `dashbord/office/edit/${id}`,
            qs.stringify({
                isActive: value ? 1 : 0
            }), {
            headers: { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") }
        }).then(response => {
            this.componentDidMount()
            toast("تم التعديل بنجاح", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }).catch(error => {
            this.setState({ spinnerBtn: false })
            console.log(error.response.data)
        })
    }

    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    if (this.state.spin) {
                        return (
                            <div >
                                {/* <TimePic/> */}
                                <div id='categoryContentContinerFirst' style={{ display: 'flex' }}>
                                    <Component initialState={{ isShown: false, name: '', speice: '', fprice: '', type: 0, }}>
                                        {({ state, setState }) => (
                                            <React.Fragment>
                                                <SideSheet
                                                    isShown={state.isShown}
                                                    position={Position.LEFT}
                                                    onCloseComplete={() => setState({ isShown: false })}
                                                >
                                                    <div margin={40}>
                                                        <div id='titleAddUser'  style={{ backgroundColor: '#0c4c91' }}>اضافه مدينه</div>
                                                        <div id='inputAdd2Continer' >

                                                            <div id={'ContinerInPut'} >
                                                                <label> اسم المدينه </label>
                                                                <input autoComplete='off' type='text' placeholder='اسم المدينه' id='InputTExtDash111' style={{ height: 32, width: 250 }}
                                                                    onChange={(e) => setState({ name: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'} >
                                                                <label> سعر التوصيل </label>
                                                                <input autoComplete='off' type='number' placeholder='سعر التوصيل' id='InputTExtDash111' style={{ height: 32, width: 250 }}
                                                                    onChange={(e) => setState({ speice: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'}>
                                                                <label>توصيل سريع</label>
                                                                <Form.Control as="select" id='InputTExtDash1' style={{ height: 38 }}
                                                                    onChange={(even) => {
                                                                        if (even.target.value !== 'Select') {
                                                                            setState({ type: Number(even.target.value) })
                                                                        }
                                                                    }}>
                                                                    <option value='Select'>اختيار</option>
                                                                    <option value={1}>نعم</option>
                                                                    <option value={0} >كلا</option>
                                                                </Form.Control>
                                                            </div>
                                                            {state.type === 1 ?
                                                                <React.Fragment>

                                                                    <div id={'ContinerInPut'} >
                                                                        <label> سعر التوصيل السريع </label>
                                                                        <input autoComplete='off' type='number' placeholder='سعر التوصيل السريع' id='InputTExtDash111' style={{ height: 32, width: 250 }}
                                                                            onChange={(e) => setState({ fprice: e.target.value })} />
                                                                    </div>
                                                                </React.Fragment>
                                                                : null}
                                                        </div>
                                                        <div id='btnAddContiner'>
                                                            <div id='BtnAddUser' style={{ backgroundColor: '#0c4c91' }} onClick={() => {
                                                                axios.post(host + `dashbord/city/add`,
                                                                    qs.stringify({
                                                                        name: state.name,
                                                                        shipping_price: state.speice,
                                                                        fast_shipping: state.type,
                                                                        fast_price: state.type === 0 ? 0 : state.fprice,
                                                                        governorate_id:this.props.match.params.id
                                                                    }), {
                                                                    headers: { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") }
                                                                }).then(response => {
                                                                    setState({ isShown: false })
                                                                    this.componentDidMount()
                                                                    toast("تمت الاضافه بنجاح", {
                                                                        position: "bottom-center",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true
                                                                    });
                                                                }).catch(error => {
                                                                    this.setState({ spinnerBtn: false })
                                                                    console.log(error.response.data)
                                                                })

                                                            }}>اضافه</div>
                                                        </div>

                                                    </div>
                                                </SideSheet>
                                                <div id='btnADDCateGory' onClick={() => setState({ isShown: true })} >اضافة مدينه</div>

                                            </React.Fragment>
                                        )}
                                    </Component>


                                </div>
                                <div className='DataTableContiner'>
                                    <MuiThemeProvider
                                        theme={this.getMuiTheme()}>
                                        <MaterialDatatable data={this.state.recipes} columns={columns} options={options} />
                                    </MuiThemeProvider>
                                </div>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>

                        )
                    }
                    else {
                        return (
                            <div style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Spinner />
                            </div>
                        )
                    }

                }
                }
            </Context.Consumer>
        )
    }
}


export default Items;