import React from 'react';
import Context from '../../../assets/js/Context';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Component from "@reactions/component";
import { Row, Col } from 'react-bootstrap';
import { Pane, Dialog, Spinner, SideSheet, Position } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from "universal-cookie";
import Login from '../../common/login'
import { Link } from 'react-router-dom';
import host from '../../../assets/js/Host';
import axios from 'axios';
// import imageCompression from 'browser-image-compression';
import CardCate from '../../common/cardCategory';

const cookies = new Cookies();
class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category: [],
            categoryid: [],
            spin: true,
            spinCate: true,
            BtnAdd: false,
            Bread: false,
            Breadcrumb: [],
            nameCate: '',
            image: [],
        }
    }

    componentDidMount() {
        var Breadcrumb = JSON.parse(localStorage.getItem('Breadcrumb'))
        var header = { "Content-Type": "application/json", token: cookies.get("token") };
        axios.get(host + `category/category/${this.props.match.params.id}`, { headers: header })
            .then(response => {
                // console.log(response.data.data)
                if (response.status === 200) {
                    this.setState({
                        categoryid: response.data.data.chlid_categories,
                        spinCate: false, Breadcrumb
                    })
                }
            })
            .catch((error) => {
                this.setState({ spinCate: false })
                console.log('error ' + error)
            })
    }
    deleteCate(id) {
        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.delete(host + `category/${id}`, { headers: header })
            .then(response => {
                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount()
            })
            .catch(error => {
                console.log(error.response)
                toast('لايمكن حذف القسم', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });
    }

    render() {

        return (
            <Context.Consumer>
                {ctx => {
                    if (cookies.get("token")) {
                        if (!this.state.spinCate) {
                            return (
                                <div style={{ direction: 'rtl' }}>
                                    <div id='breadContiner'>
                                        <Link to='/Category'>
                                            <div id='PathBTnNav'> الاقسام </div>
                                        </Link>
                                        {this.state.Breadcrumb.map((bread, i) =>
                                            <div key={bread.id} id='breadContiner1'>
                                                <div style={{ marginLeft: 10, marginRight: 10 }} > / </div>
                                                {bread.id === Number(this.props.match.params.id) ?
                                                    <div id='PathBTnNav' >{bread.name} </div> :
                                                    <Link to={`/CategoryByid1/${bread.id}`}
                                                        onClick={() => {
                                                            var items = JSON.parse(localStorage.getItem('Breadcrumb'));
                                                            items.splice(i + 1, this.state.Breadcrumb.length - i);
                                                            localStorage.setItem('Breadcrumb', JSON.stringify(items));
                                                        }} >
                                                        <div id='PathBTnNav' >{bread.name} </div>
                                                    </Link>
                                                }
                                            </div>
                                        )}
                                    </div>

                                    <Component initialState={{ isShown: false, spinbtn: false }}>
                                        {({ state, setState }) => (
                                            <React.Fragment>
                                                <SideSheet
                                                    isShown={state.isShown}
                                                    position={Position.LEFT}
                                                    onCloseComplete={() => setState({ isShown: false })}
                                                >
                                                    <div margin={40}>
                                                        <div id='titleAddUser' style={{ backgroundColor: '#3A3A3A' }}>اضافه قسم</div>
                                                        <div id='inputAdd2Continer' >
                                                            <div id={'ContinerInPut'} >
                                                                <label>الاسم </label>
                                                                <input autoComplete='off' type='text' placeholder='الاسم' id='InputTExtDash111'
                                                                    onChange={(e) => this.setState({ nameCate: e.target.value })} />
                                                            </div>

                                                            <div id={'ContinerInPut'}>
                                                                <label>صورة</label>
                                                                <input type="file" name="profilePicBtn" id='InputTExtDash111Image' accept="image/png, image/jpeg"
                                                                    onChange={(event) => { this.setState({ image: event.target.files }) }} />
                                                            </div>
                                                        </div>
                                                        <div id='btnAddContiner'>
                                                            {!state.spinbtn ?
                                                                <div id='BtnAddUser' style={{ backgroundColor: '#3A3A3A' }} onClick={() => {
                                                                    var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
                                                                    let formData = new FormData();
                                                                    setState({ spinbtn: true })
                                                                    formData.append("category_id", this.props.match.params.id);
                                                                    formData.append("name", this.state.nameCate);
                                                                    formData.append("file", this.state.image[0]);
                                                                    axios({ url: host + `category/`, method: "POST", data: formData, headers: header })
                                                                        .then(response => {
                                                                            this.setState({ image: '' })
                                                                            setState({ isShown: false, spinbtn: false })
                                                                            toast('تمت اضافة القسم بنجاح', {
                                                                                position: "bottom-center",
                                                                                autoClose: 5000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: true
                                                                            });
                                                                            this.componentDidMount();
                                                                        })
                                                                        .catch(error => {
                                                                            setState({ spinbtn: false })
                                                                            console.log(error)
                                                                        })

                                                                }}>اضافة</div>
                                                                :
                                                                <div id='BtnAddUser' style={{ backgroundColor: '#3A3A3A' }} ><Spinner /></div>}
                                                        </div>

                                                    </div>
                                                </SideSheet>
                                                <div id='categoryContentContinerFirst' style={this.state.BtnAdd ? { display: 'none' } : { display: 'flex' }}>
                                                    <div id='btnADDCateGory' onClick={() => setState({ isShown: true })}>اضافة قسم</div>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </Component>

                                    <Row style={{ margin: 0 }}>
                                        {this.state.categoryid.map(cate =>
                                            <Col key={cate.id} id='ColFolder' xs={6} md={4} lg={3} xl={2} >
                                                <Component initialState={{ menuOpen: false, edit: false, isShown: false, image: [] }}>
                                                    {({ state, setState }) => (
                                                        <div id='FolderCateContiner'>
                                                            <div id='MenuBtnCateCont' onClick={() => setState({ menuOpen: !state.menuOpen })}>
                                                                <MoreVertIcon />
                                                            </div>
                                                            {state.menuOpen ?
                                                                <div id='menuEditAndDeleteContinerCateNew'>
                                                                    <Component initialState={{ isShown: false, nameCate: cate.name, spinBtn: false }}>
                                                                        {({ state, setState }) => (
                                                                            <React.Fragment>
                                                                                <SideSheet
                                                                                    isShown={state.isShown}
                                                                                    position={Position.LEFT}
                                                                                    onCloseComplete={() => setState({ isShown: false })}
                                                                                >
                                                                                    <div margin={40}>
                                                                                        <div id='titleAddUser' style={{ backgroundColor: '#3A3A3A' }}>تعديل قسم</div>
                                                                                        <div id='inputAdd2Continer' >
                                                                                            <div id={'ContinerInPut'} >
                                                                                                <label>الاسم </label>
                                                                                                <input autoComplete='off' type='text' placeholder='الاسم' id='InputTExtDash111'
                                                                                                    value={state.nameCate} onChange={(e) => setState({ nameCate: e.target.value })} />
                                                                                            </div>

                                                                                            <div id={'ContinerInPut'}>
                                                                                                <label>صورة</label>
                                                                                                <input type="file" name="profilePicBtn" id='InputTExtDash111Image' accept="image/png, image/jpeg"
                                                                                                    onChange={(event) => { setState({ image: event.target.files }) }} />
                                                                                            </div>
                                                                                        </div>
                                                                                        {!state.spinBtn ?
                                                                                            <div id='btnAddContiner'>
                                                                                                <div id='BtnAddUser' style={{ backgroundColor: '#3A3A3A' }} onClick={() => {
                                                                                                    setState({ spinBtn: true })
                                                                                                    var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
                                                                                                    let formData = new FormData();
                                                                                                    formData.append("name", state.nameCate);
                                                                                                    formData.append("file", state.image[0]);

                                                                                                    axios({ url: host + `category/edit/${cate.id}`, method: "PUT", data: formData, headers: header })
                                                                                                        .then(response => {
                                                                                                            setState({ isShown: false, spinBtn: false })
                                                                                                            this.setState({ event: false })
                                                                                                            toast('تم تعديل القسم بنجاح', {
                                                                                                                position: "bottom-center",
                                                                                                                autoClose: 5000,
                                                                                                                hideProgressBar: false,
                                                                                                                closeOnClick: true,
                                                                                                                pauseOnHover: true,
                                                                                                                draggable: true
                                                                                                            });
                                                                                                            this.componentDidMount();
                                                                                                        })
                                                                                                        .catch(error => {
                                                                                                            setState({ isShown: false, spinBtn: false })
                                                                                                            console.log(error)
                                                                                                        })

                                                                                                }}>تعديل</div>
                                                                                            </div>
                                                                                            : <div id='btnAddContiner'>
                                                                                                <div id='BtnAddUser' style={{ backgroundColor: '#c0c0c7' }} >
                                                                                                    <Spinner size={24} />
                                                                                                </div>
                                                                                            </div>
                                                                                        }

                                                                                    </div>
                                                                                </SideSheet>
                                                                                <div id='MenuBtnCateCont' onClick={() => setState({ isShown: true })}>
                                                                                    <EditIcon />
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </Component>

                                                                    <Component initialState={{ isShown: false }}>
                                                                        {({ state, setState }) => (
                                                                            <Pane>
                                                                                <Dialog
                                                                                    isShown={state.isShown}
                                                                                    title="حذف قسم "
                                                                                    intent="danger"
                                                                                    onCloseComplete={() => setState({ isShown: false })}
                                                                                    confirmLabel="حذف"
                                                                                    cancelLabel="الغاء"
                                                                                    onConfirm={() => {
                                                                                        setState({ isShown: false })
                                                                                        this.deleteCate(cate.id)
                                                                                    }}
                                                                                >
                                                                                    <span id='msgDelete'> هل انت متأكد من عملية الحذف</span>
                                                                                </Dialog>
                                                                                <div id='MenuBtnCateCont' style={{ top: 25 }} onClick={() => setState({ isShown: true, menuOpen: false })}>
                                                                                    <DeleteIcon />
                                                                                </div>
                                                                            </Pane>
                                                                        )}
                                                                    </Component>
                                                                </div> : null}

                                                            <CardCate id={cate.id} image={host + 'files/' + cate.photo} name={cate.name}
                                                                link={`/CategoryByid1/${cate.id}`} chaid={true} />
                                                        </div>
                                                    )}
                                                </Component>
                                            </Col>
                                        )}
                                    </Row>

                                    <ToastContainer
                                        position="bottom-left"
                                        autoClose={10000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }}>
                                    <Spinner />
                                </div>
                            )
                        }
                    }
                    else {
                        return (
                            <Login />
                        )
                    }
                }
                }

            </Context.Consumer>
        )
    }
}

export default Category;