import React from 'react';
import Context from '../../../assets/js/Context';
import Component from "@reactions/component";
import { Tabs, Tab } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { Spinner, SearchInput, Pane, Dialog } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InfoIcon from '@material-ui/icons/Info';
// import Component from "@reactions/component";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Cookies from "universal-cookie";
import host from '../../../assets/js/Host';
import axios from 'axios';
import { Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import * as moment from "moment-timezone";
const cookies = new Cookies();
class Statistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            page: [],
            Data1: [],
            page1: [],
            Data2: [],
            page2: [],
            name: '',
            phone1: '',
            phone2: '',
            price: '',
            address: '',
            email: '',
            officename: '',
            officephone: '',
            idshipment: '',
            redirect: false,
            spinPage: true,
            activePage: 0,
            search: '',
            activetap:'wating'
                }
        // this.socket();
    }
    componentDidMount(activePage, search, limit) {
        this.setState({ spinPage: true })
        // var officeId = localStorage.getItem('officeId')
        var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };

        axios.get(host + `dashbord/services/orders/all?status=0&search=${search ? search : ''}&limit=${limit ? limit : 20}&page=${activePage ? activePage : 1}`, { headers: header })
            .then(res => {
                console.log(res.data.data);
                this.setState({
                    Data: res.data.data.my_list, spinPage: false,
                    page: res.data.data
                });
            })
            .catch(error => {
                console.log(error)
            })

        axios.get(host + `dashbord/services/orders/all?status=1&search=${search ? search : ''}&limit=${limit ? limit : 20}&page=${activePage ? activePage : 1}`, { headers: header })
            .then(res => {
                // console.log(res.data.data.my_orders);
                this.setState({
                    Data1: res.data.data.my_list, spinPage: false,
                    page1: res.data.data
                });
            })
            .catch(error => {
                console.log(error.response)
            })

        axios.get(host + `dashbord/services/orders/all?status=2&search=${search ? search : ''}&limit=${limit ? limit : 20}&page=${activePage ? activePage : 1}`, { headers: header })
            .then(res => {
                // console.log(res.data.data.my_orders);
                this.setState({
                    Data2: res.data.data.my_list, spinPage: false,
                    page2: res.data.data
                });
            })
            .catch(error => {
                console.log(error.response)
            })
    }

    handlePageClick(pageNumber) {
        this.setState({ activePage: pageNumber.selected })
        this.componentDidMount(pageNumber.selected + 1)

    }

    cahngeStatus(id, value) {
        axios.put(host + `dashbord/services/orders/${id}`,
            qs.stringify({ status: value }), {
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") }
        }).then(response => {
            this.setState({ nameCate: '', BtnAdd: false })
            toast('تم تغيير حالة الطلب بنجاح', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            this.componentDidMount()
        }).catch(error => { console.log(error) })
    }
    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    return (
                        <div >

                            {!this.state.spinPage ?
                                <div id='tableAdminContiner' >
                                    <Paper className={'classesroot'} >
                                        <Tabs defaultActiveKey={this.state.activetap} id="uncontrolled-tab-example" style={{ backgroundColor: '#fff', }}>
                                            <Tab eventKey='wating' title="طلبات قيد الانتظار" tabClassName='wating' onClick={()=>this.setState({activetap:'wating'})}>
                                                <div id='headSerachLimit'>
                                                <div style={{display:'flex'}}>
                                                    <SearchInput placeholder="بحث" style={{ paddingRight: 30 }} 
                                                    value={this.state.search}  onChange={e => {
                                                       this.setState({search:e.target.value})
                                                    }} />
                                                    <div id='SEArchBTn' onClick={()=>{this.componentDidMount(1,this.state.search)}}>بحث</div>
                                                    
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <label style={{ marginLeft: 10, marginTop: 10 }}>عدد الصفوف</label>
                                                        <select className='InputTExtDash111' style={{ height: 30, width: 60, paddingRight: 5, paddingLeft: 5, textAlign: 'left' }}
                                                            name='city' onChange={(e) => {
                                                                if (e.target.value !== 'limit') {
                                                                    this.componentDidMount(1, '', e.target.value)
                                                                }
                                                            }}>
                                                            <option value={20}>20</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>
                                                        <div className='InputTExtDash111NN' style={{ height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            {this.state.page.totalItems}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='DataTableContiner' style={{ direction: 'rtl' }} >
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr style={{ textAlign: 'center' }}>
                                                                <th>#</th>
                                                                <th>الهاتف</th>
                                                                <th>العنوان</th>
                                                                <th>السعر</th>
                                                                {/* <th>بروموكود</th> */}
                                                                <th>التاريخ</th>
                                                                <th>التفاصيل</th>
                                                                <th>رفض</th>
                                                                <th>موافقة</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.Data.map((data, i) =>
                                                                <tr key={i} style={{ textAlign: 'center' }}>
                                                                    <td>{data.id}</td>
                                                                    <td>{data.phone}</td>
                                                                    <td>{data.address}</td>
                                                                    <td>{data.price}</td>
                                                                    {/* <td>{data.promo}</td> */}
                                                                    <td>{moment(data.createdAt).tz('Asia/baghdad').format('YYYY/MM/DD LT')}</td>
                                                                    <td><Link to={`/OrderServicesInfo/${data.id}`} >
                                                                        <InfoIcon style={{ color: '#e86018' }} />
                                                                    </Link></td>
                                                                    <td>
                                                                        <Component initialState={{ isShown: false }}>
                                                                            {({ state, setState }) => (
                                                                                <Pane>
                                                                                    <Dialog
                                                                                        isShown={state.isShown}
                                                                                        title="رفض الطلب"
                                                                                        intent="danger"
                                                                                        onCloseComplete={() => setState({ isShown: false })}
                                                                                        confirmLabel="تاكيد"
                                                                                        cancelLabel="الغاء"
                                                                                        onConfirm={() => { this.cahngeStatus(data.id, 2) }}
                                                                                    >
                                                                                        هل انت متاكد من عملية رفض الطلب
                                                                                </Dialog>
                                                                                    <CancelIcon style={{ color: '#d26a74', cursor: 'pointer' }} onClick={() => setState({ isShown: true })} />
                                                                                </Pane>
                                                                            )}
                                                                        </Component>
                                                                    </td>
                                                                    <td><Component initialState={{ isShown: false }}>
                                                                        {({ state, setState }) => (
                                                                            <Pane>
                                                                                <Dialog
                                                                                    isShown={state.isShown}
                                                                                    title="استلام الطلب"
                                                                                    onCloseComplete={() => setState({ isShown: false })}
                                                                                    confirmLabel="موافقه"
                                                                                    cancelLabel="الغاء"
                                                                                    onConfirm={() => { this.cahngeStatus(data.id, 1) }}
                                                                                >
                                                                                    هل انت متاكد من عملية استلام الطلب
                                                                                </Dialog>
                                                                                <CheckCircleIcon style={{ color: '#6ad287', cursor: 'pointer' }} onClick={() => setState({ isShown: true })} />
                                                                            </Pane>
                                                                        )}
                                                                    </Component>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>

                                                </div>
                                                <div id='pagnationContiner'>
                                                    <ReactPaginate
                                                        previousLabel={'السابق'}
                                                        nextLabel={'التالي'}
                                                        breakLabel={'...'}
                                                        breakClassName={'break-me'}
                                                        pageCount={this.state.page.totalpages}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={8}
                                                        forcePage={this.state.activePage}
                                                        onPageChange={(page) => this.handlePageClick(page)}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                        activeClassName={'active'}
                                                    />
                                                </div>
                                            </Tab>
                                            <Tab eventKey="done" title="طلبات تم انجازها " tabClassName='done' onClick={()=>this.setState({activetap:'done'})}>
                                                <div id='headSerachLimit'>
                                                <div style={{display:'flex'}}>
                                                    <SearchInput placeholder="بحث" style={{ paddingRight: 30 }} 
                                                    value={this.state.search}  onChange={e => {
                                                       this.setState({search:e.target.value})
                                                    }} />
                                                    <div id='SEArchBTn' onClick={()=>{this.componentDidMount(1,this.state.search)}}>بحث</div>
                                                    
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <label style={{ marginLeft: 10, marginTop: 10 }}>عدد الصفوف</label>
                                                        <select className='InputTExtDash111' style={{ height: 30, width: 60, paddingRight: 5, paddingLeft: 5, textAlign: 'left' }}
                                                            name='city' onChange={(e) => {
                                                                if (e.target.value !== 'limit') {
                                                                    this.componentDidMount(1, '', e.target.value)
                                                                }
                                                            }}>
                                                            <option value={20}>20</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>
                                                        <div className='InputTExtDash111NN' style={{ height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            {this.state.page1.totalItems}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='DataTableContiner' style={{ direction: 'rtl' }} >
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr style={{ textAlign: 'center' }}>
                                                                <th>#</th>
                                                                <th>الهاتف</th>
                                                                <th>العنوان</th>
                                                                <th>السعر</th>
                                                                {/* <th>بروموكود</th> */}
                                                                <th>التاريخ</th>
                                                                <th>التفاصيل</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.Data1.map((data, i) =>
                                                                <tr key={i} style={{ textAlign: 'center' }}>
                                                                    <td>{data.id}</td>
                                                                    <td>{data.phone}</td>
                                                                    <td>{data.address}</td>
                                                                    <td>{data.price}</td>
                                                                    {/* <td>{data.promo}</td> */}
                                                                    <td>{moment(data.createdAt).tz('Asia/baghdad').format('YYYY/MM/DD LT')}</td>
                                                                    <td><Link to={`/OrderServicesInfo/${data.id}`} >
                                                                        <InfoIcon style={{ color: '#e86018' }} />
                                                                    </Link></td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>

                                                </div>
                                                <div id='pagnationContiner'>
                                                    <ReactPaginate
                                                        previousLabel={'السابق'}
                                                        nextLabel={'التالي'}
                                                        breakLabel={'...'}
                                                        breakClassName={'break-me'}
                                                        pageCount={this.state.page1.totalpages}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={8}
                                                        forcePage={this.state.activePage}
                                                        onPageChange={(page) => this.handlePageClick(page)}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                        activeClassName={'active'}
                                                    />
                                                </div>
                                            </Tab>
                                            <Tab eventKey="cancel" title="طلبات تم الغاؤها " tabClassName='cancel' onClick={()=>this.setState({activetap:'cancel'})}>
                                                <div id='headSerachLimit'>
                                                <div style={{display:'flex'}}>
                                                    <SearchInput placeholder="بحث" style={{ paddingRight: 30 }} 
                                                    value={this.state.search}  
                                                    oncancel={()=>this.componentDidMount(1,'')}
                                                    onChange={e => {
                                                       
                                                       this.setState({search:e.target.value})
                                                    }} />
                                                    <div id='SEArchBTn' onClick={()=>{this.componentDidMount(1,this.state.search)}}>بحث</div>
                                                    
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <label style={{ marginLeft: 10, marginTop: 10 }}>عدد الصفوف</label>
                                                        <select className='InputTExtDash111' style={{ height: 30, width: 60, paddingRight: 5, paddingLeft: 5, textAlign: 'left' }}
                                                            name='city' onChange={(e) => {
                                                                if (e.target.value !== 'limit') {
                                                                    this.componentDidMount(1, '', e.target.value)
                                                                }
                                                            }}>
                                                            <option value={20}>20</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>
                                                        <div className='InputTExtDash111NN' style={{ height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            {this.state.page2.totalItems}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='DataTableContiner' style={{ direction: 'rtl' }} >
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr style={{ textAlign: 'center' }}>
                                                                <th>#</th>
                                                                <th>الهاتف</th>
                                                                <th>العنوان</th>
                                                                <th>السعر</th>
                                                                {/* <th>بروموكود</th> */}
                                                                <th>التاريخ</th>
                                                                <th>التفاصيل</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.Data2.map((data, i) =>
                                                                <tr key={i} style={{ textAlign: 'center' }}>
                                                                    <td>{data.id}</td>
                                                                    <td>{data.phone}</td>
                                                                    <td>{data.address}</td>
                                                                    <td>{data.price}</td>
                                                                    {/* <td>{data.promo}</td> */}
                                                                    <td>{moment(data.createdAt).tz('Asia/baghdad').format('YYYY/MM/DD LT')}</td>
                                                                    <td><Link to={`/OrderServicesInfo/${data.id}`} >
                                                                        <InfoIcon style={{ color: '#e86018' }} />
                                                                    </Link></td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>

                                                </div>
                                                <div id='pagnationContiner'>
                                                    <ReactPaginate
                                                        previousLabel={'السابق'}
                                                        nextLabel={'التالي'}
                                                        breakLabel={'...'}
                                                        breakClassName={'break-me'}
                                                        pageCount={this.state.page2.totalpages}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={8}
                                                        forcePage={this.state.activePage}
                                                        onPageChange={(page) => this.handlePageClick(page)}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                        activeClassName={'active'}
                                                    />
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </Paper>
                                    <ToastContainer
                                        position="bottom-left"
                                        autoClose={10000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                                : <div style={{ width: '100%', height: 'calc(100vh - 100px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Spinner />
                                </div>
                            }

                        </div>
                    )
                }}
            </Context.Consumer>
        )
    }
}
export default Statistics;