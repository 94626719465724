import React from 'react';
import ScrollToTop from './assets/js/scrolToTop';
import Login from './component/common/login';
import Context from './assets/js/Context';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Dashboard from './component/common/sideBar';
import CityDash from './component/dash/office/cityDash';
import CategoryById from './component/dash/category/cateByid';
import CategoryById1 from './component/dash/category/catebyid1';
import NotFound from './assets/js/NotFound';
import AddOffice from './component/dash/office/addOffice';
import AddItem from './component/common/AddItem';
import EditItem from './component/common/editItem';
import OfficeOption from './component/dash/office/officeOption';
import ActiveItem from './component/dash/office/ِActiveItem';
import UnActiveItem from './component/dash/office/UnactiveItem';
import ItemCount from './component/dash/office/underCount';
import OffersItem from './component/dash/office/offersItem';
import CateByOffice from './component/dash/office/cateByOffice';
import CateByOffice1 from './component/dash/office/cateByOffice1';
import CateByOffice2 from './component/dash/office/cateByOffice2';
import OrderInfo from './component/dash/office/OrderInfo';
import OrderServicesInfo from './component/dash/office/OrderServicesInfo';

// import Cookies from "universal-cookie";
// import host from './assets/js/Host';
// import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/login.css';
import './App.css';

class App extends React.Component {
  constructor() {
    super();
    this.state = {}
  }
  render() {
    return (
      <div >
        <Context.Provider value={{
          value: this.state, action: {
            // AddItem: ( value) => {
            //   this.setState({updateData:value})
            // },
          }
        }}>
          <div className="App">
            <BrowserRouter>
              <ScrollToTop>
                <Switch>
                
                <Route exact path='/' component={Login} />
                   <Route path='/Category' component={Dashboard} /> 
                   <Route path='/Items' component={Dashboard} /> 
                   <Route path='/Services' component={Dashboard} />
                   <Route path='/Media' component={Dashboard} />
                   <Route path='/Banner' component={Dashboard} />
                   <Route path='/Orders' component={Dashboard} />
                   <Route path='/OrdersFast' component={Dashboard} />
                   <Route path='/Setting' component={Dashboard} />
                   <Route path='/Users' component={Dashboard} />
                   <Route path='/Gifts' component={Dashboard} />
                   <Route path='/Governorate' component={Dashboard} />
                   <Route path='/OrderServices' component={Dashboard} />
                   {/* <Route path='/Promocode' component={Dashboard} /> */}
                   <Route path='/AddOffice' component={AddOffice} />
                   <Route path='/OrderInfo/:id' component={OrderInfo} />
                   <Route path='/OrderServicesInfo/:id' component={OrderServicesInfo} />
                   <Route path='/AddItem/:id' component={AddItem} />
                   <Route path='/EditItem/:id' component={EditItem} />
                   <Route path='/CityDash/:id' component={CityDash} />
                   <Route path='/CategoryByid/:id' component={CategoryById} />
                   <Route path='/CategoryByid1/:id' component={CategoryById1} />
                   <Route path='/OfficeOption/:id' component={OfficeOption} />
                   <Route path='/ActiveItem/:id' component={ActiveItem} />
                   <Route path='/UnActiveItem/:id' component={UnActiveItem} />
                   <Route path='/ItemCount/:id' component={ItemCount} />
                   <Route path='/OffersItem/:id' component={OffersItem} />
                   <Route path='/CateByOffice/:id' component={CateByOffice} />
                   <Route path='/CateByOffice1/:id' component={CateByOffice1} />
                   <Route path='/CateByOffice2/:id' component={CateByOffice2} />
                  <Route exact={true} path='*' component={NotFound} />
                </Switch>
              </ScrollToTop>
            </BrowserRouter>
          </div>
        </Context.Provider>

      </div>
    );
  }
}

export default App;
